@import "styles/_variables.scss";

@mixin breakpoint($point) {
  @if $point == small {
    @media (min-width: $phone) { @content; }
  }
  @else if $point == medium {
    @media (min-width: $tablet) { @content; }
  }
}
