@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=VT323&display=swap);
@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css?family=Bungee+Shade&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=VT323&display=swap);
@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css?family=Bungee+Shade&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=VT323&display=swap);
@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css?family=Bungee+Shade&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=VT323&display=swap);
@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css?family=Bungee+Shade&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=VT323&display=swap);
@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css?family=Bungee+Shade&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=VT323&display=swap);
@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css?family=Bungee+Shade&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=VT323&display=swap);
@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css?family=Bungee+Shade&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=VT323&display=swap);
@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css?family=Bungee+Shade&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=VT323&display=swap);
@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css?family=Bungee+Shade&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=VT323&display=swap);
@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css?family=Bungee+Shade&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=VT323&display=swap);
@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css?family=Bungee+Shade&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=VT323&display=swap);
@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css?family=Bungee+Shade&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=VT323&display=swap);
@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css?family=Bungee+Shade&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=VT323&display=swap);
@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css?family=Bungee+Shade&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=VT323&display=swap);
@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css?family=Bungee+Shade&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=VT323&display=swap);
@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css?family=Bungee+Shade&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=VT323&display=swap);
@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css?family=Bungee+Shade&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=VT323&display=swap);
@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css?family=Bungee+Shade&display=swap);
@keyframes Movement_fadeIn__2v7BH {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes Movement_fadeOut__37EUW {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.Movement_FadeIn__1YGtm {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Movement_fadeIn__2v7BH;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Movement_FadeOut__3Mb7V {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Movement_fadeOut__37EUW;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Movement_Flash__1iw-q {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Movement_fadeIn__2v7BH, Movement_fadeOut__37EUW;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .Movement_Flash__1iw-q p {
    font-weight: 900; }

@keyframes Movement_fadeIn__2v7BH {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes Movement_fadeOut__37EUW {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.Movement_FadeIn__1YGtm {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Movement_fadeIn__2v7BH;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Movement_FadeOut__3Mb7V {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Movement_fadeOut__37EUW;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Movement_Flash__1iw-q {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Movement_fadeIn__2v7BH, Movement_fadeOut__37EUW;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .Movement_Flash__1iw-q p {
    font-weight: 900; }

@keyframes Movement_fadeIn__2v7BH {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes Movement_fadeOut__37EUW {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.Movement_FadeIn__1YGtm {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Movement_fadeIn__2v7BH;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Movement_FadeOut__3Mb7V {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Movement_fadeOut__37EUW;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Movement_Flash__1iw-q {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Movement_fadeIn__2v7BH, Movement_fadeOut__37EUW;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .Movement_Flash__1iw-q p {
    font-weight: 900; }

.Movement_react-datepicker__A4F-b {
  font-size: 1em; }

.Movement_react-datepicker__header__3vuBm {
  padding-top: 0.8em; }

.Movement_react-datepicker__month__1bUYS {
  margin: 0.4em 1em; }

.Movement_react-datepicker__day-name__1Icvm, .Movement_react-datepicker__day__fnJQG {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em; }

.Movement_react-datepicker__day--selected___UFjm {
  background-color: #8b33e2; }

.Movement_react-datepicker__current-month__3GjMW {
  font-size: 1em; }

.Movement_react-datepicker__navigation__2Hlsg {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent; }

.Movement_react-datepicker__navigation--previous__1bByd {
  border-top-color: #8b33e2;
  border-left-color: #8b33e2;
  border-bottom-color: #8b33e2;
  border-right-color: grey;
  left: .2em;
  box-shadow: none; }

.Movement_react-datepicker__navigation--next__2BCn7 {
  border-top-color: #8b33e2;
  border-right-color: #8b33e2;
  border-bottom-color: #8b33e2;
  border-left-color: grey;
  box-shadow: none;
  right: .2em; }

.Movement_Card__2zEcR, .Movement_DAOCard__2mce5 {
  box-sizing: border-box;
  overflow: hidden;
  background-color: #1a1a1a;
  border-radius: 4rem;
  border: 1rem solid #8b33e2;
  padding-top: 3.6rem;
  padding-bottom: 2.6rem;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: left; }

.Movement_ButtonRaised__3pOvR {
  font-family: "Muli", sans-serif;
  border-radius: 15px;
  box-shadow: 2px, 2px; }

.Movement_InputRounded__5kv6W {
  min-width: 8.9rem;
  padding: 0.5rem;
  min-height: 1rem;
  box-sizing: border-box;
  border: 1px solid #d6d6d6;
  outline: none;
  border: none;
  background-color: white;
  border: solid #d6d6d6 1px;
  min-width: 300px;
  max-width: 100%;
  font-size: 21px;
  padding: 20px 20px 10px 20px;
  border-radius: 35px; }

.Movement_Movement__20cDN {
  position: relative;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (min-width: 700px) {
    .Movement_Movement__20cDN {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 8rem;
      padding-right: 8rem; } }

.Movement_Title__1ghLL {
  width: 100%;
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 1rem;
  font-size: 6rem;
  color: #ffffff; }

.Movement_DescriptionTextWrapper__1SclW {
  max-width: 60rem;
  margin-bottom: 3rem;
  font-size: 2.4rem;
  display: flex; }
  .Movement_DescriptionTextWrapper__1SclW.Movement_Expanded__JGexj > .Movement_DescriptionText__2uD40 {
    overflow: auto;
    white-space: normal; }
  .Movement_DescriptionTextWrapper__1SclW.Movement_Expanded__JGexj > .Movement_MoreButton__2uP7k {
    display: none; }

.Movement_DescriptionText__2uD40 {
  text-align: justify;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.Movement_MoreButton__2uP7k:hover {
  cursor: pointer; }

.Movement_DAOCard__2mce5 {
  margin-bottom: 8rem;
  max-width: 60rem;
  width: 100%;
  background-color: #ffffff;
  padding-top: 3rem;
  text-align: center; }

.Movement_Container__2CEqS {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }

.Movement_Amount__KC2Dz {
  border: 0.2rem solid #555555;
  border-radius: 3.2rem;
  height: 7.5rem;
  width: 20rem;
  padding-left: .5rem;
  padding-right: .5rem;
  text-align: center;
  font-size: 6rem;
  color: #000000;
  /* Firefox */
  -moz-appearance: textfield; }
  @media (min-width: 700px) {
    .Movement_Amount__KC2Dz {
      width: 30rem; } }
  .Movement_Amount__KC2Dz:focus {
    outline: 0; }

.Movement_Error__1cHN2 {
  color: #ef495f;
  margin-top: 1.625rem;
  margin-bottom: 1.5rem; }

.Movement_MinAmountText__3k_gQ {
  font-size: 1.8rem;
  margin-top: .75rem;
  margin-bottom: 1.5rem;
  color: #555555;
  text-align: center; }

.Movement_CurrencyTypeText__b0j_h {
  border: .1rem solid #d6d6d6;
  border-radius: 1.6rem;
  margin-bottom: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 1.6rem;
  color: #000000;
  font-weight: 700; }

.Movement_MinMaxButton__3kQTs {
  transition: all .15s linear;
  border: 0.2rem solid #8b33e2;
  border-radius: 2rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  padding-top: .8rem;
  padding-bottom: .8rem;
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  background-color: inherit;
  font-size: 1.4rem;
  font-weight: 600; }
  .Movement_MinMaxButton__3kQTs:hover {
    background-color: #8b33e2;
    color: #ffffff; }
  .Movement_MinMaxButton__3kQTs:focus {
    outline: none;
    text-decoration: underline; }
  .Movement_MinMaxButton__3kQTs.Movement_SelectedButton__39hpQ {
    background-color: #8b33e2;
    color: #ffffff; }

.Movement_MainButton__2AXlB {
  transition: all .15s linear;
  border: 0.5rem solid #8b33e2;
  border-radius: 3.6rem;
  min-height: 8rem;
  width: 20rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding-top: .4rem;
  padding-bottom: .4rem;
  color: white;
  background-color: #8b33e2;
  font-size: 3.2rem;
  font-weight: 600; }
  @media (min-width: 700px) {
    .Movement_MainButton__2AXlB {
      width: 30rem; } }
  .Movement_MainButton__2AXlB:hover {
    background-color: #ffffff;
    color: #000000; }

.Movement_ButtonWarning__3aFdv {
  margin-top: 2 rem;
  color: red;
  font-size: 0.5 rem; }

.Movement_PoolSnapshot__me41w {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border: 0.2rem solid #000000;
  border-radius: 3rem;
  font-family: "VT323", monospace; }

.Movement_SnapshotHeader__HthdK {
  width: 100%;
  margin-bottom: 1rem;
  text-align: center;
  color: #000000;
  font-size: 3.6rem; }

.Movement_SnapshotStat__3utsk {
  display: flex;
  flex-direction: column;
  min-width: 27.33%;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  color: #000000;
  font-family: "VT323", monospace;
  font-size: 3.5rem;
  text-align: center; }

.Movement_SnapshotLabel__1eYiV {
  margin-top: .5rem;
  font-size: 2.4rem; }

.Movement_MarginTop__1fmuK {
  margin-top: 2rem; }

.Movement_MarginTopBottom__QF5rx {
  margin-top: 3.5rem;
  margin-bottom: 1rem; }

.Movement_MembersList__2yEKP {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: left;
  font-weight: normal;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 1rem;
  margin-right: 1rem; }

.Movement_MemberListItem__1aK5d {
  width: 100%;
  margin-top: 2rem;
  font-weight: normal; }

.Movement_MemberName__3Pd4M {
  width: 100%;
  color: #000000; }

.Movement_MemberInfoRow__1lGk5 {
  width: 100%;
  display: flex;
  justify-content: space-between; }

.Movement_MemberAddress__1EA1k,
.Movement_MemberAmount__15u-l,
.Movement_MemberApproved__Gi_bs,
.Movement_MemberIdleTokenBalance__3FVkp {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #000000; }

.Movement_MemberAddress__1EA1k {
  width: 38%;
  padding-right: 2rem; }

.Movement_MemberAmount__15u-l {
  width: 25%;
  padding-right: 2rem; }

.Movement_MemberApproved__Gi_bs {
  width: 45%; }

.Movement_MemberIdleTokenBalance__3FVkp {
  display: inline-block;
  width: calc(100% - 7rem); }

.Movement_MemberQuitButton__1-v6n {
  height: 100%;
  vertical-align: top;
  color: #8b33e2;
  background: none;
  font-size: 1rem;
  padding-left: 1rem;
  text-decoration: underline; }

.Movement_FilterProposalsWrapper__1GTod {
  width: 100%;
  margin-top: -2rem;
  margin-bottom: -1rem;
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 700px) {
    .Movement_FilterProposalsWrapper__1GTod {
      width: calc(100% - 5rem); } }

.Movement_FilterProposals__2xK1O {
  display: flex;
  align-items: center;
  font-size: 14px; }
  @media (min-width: 700px) {
    .Movement_FilterProposals__2xK1O {
      align-items: baseline; } }
  .Movement_FilterProposals__2xK1O > label {
    color: #000000;
    width: 28rem; }
  .Movement_FilterProposals__2xK1O > select {
    min-width: 0; }

.Movement_ProposalCardWrapper__3L6Fl {
  width: 100%;
  transition: all .15s linear;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 20rem;
  margin-top: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 3rem;
  padding-bottom: 2.4rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  border-radius: 3.5rem;
  background-color: #555555; }
  @media (min-width: 700px) {
    .Movement_ProposalCardWrapper__3L6Fl {
      width: calc(100% - 5rem); } }
  .Movement_ProposalCardWrapper__3L6Fl:hover {
    background-color: #8b33e2; }

.Movement_ProposalTitleRow__2wben {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  text-align: left; }

.Movement_MemberTitleRow__36pxV {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  text-align: left; }

.Movement_ProposalDescription__3gPen {
  margin-top: 1rem;
  text-align: left; }

.Movement_ProposalViewButton__1gfV4 {
  position: absolute;
  bottom: 2.4rem;
  text-decoration: underline; }

.Movement_ProposalVotes__2dESm {
  position: absolute;
  bottom: 2.4rem;
  right: 2.4rem; }

.Movement_DetailsCard__1YMMs {
  position: relative;
  background-color: #555555;
  text-align: left; }

.Movement_CloseButton__137ny, .Movement_CreateCloseButton__S4RF4 {
  position: absolute;
  right: 2rem;
  top: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 3rem;
  color: #ffffff; }

.Movement_HeaderRow__1V44s {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 5rem;
  margin-right: 3rem; }

.Movement_HeaderRowTwo__2fZbb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 2rem;
  margin-right: 3rem; }

.Movement_ProposalMoreInfo__2tSSl {
  color: #ffffff;
  font-weight: 600; }
  .Movement_ProposalMoreInfo__2tSSl:hover {
    text-decoration: underline; }

.Movement_ProposalInfoText__3-Ao2 {
  margin-top: 2rem; }

.Movement_VoteContainer__38bHq {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
  margin-bottom: 8rem; }

.Movement_VoteButtonWrapper__1O41Y {
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2.4rem; }

.Movement_VoteButton__2xMun {
  background-color: #1a1a1a;
  margin-top: .4rem;
  height: 7.2rem;
  width: 7.2rem;
  border-radius: 10rem; }

.Movement_DownVote__2Z4tA {
  padding-top: 1rem; }

.Movement_ModalForeground__1_rVW {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 5000;
  background-color: black;
  opacity: 0.65;
  display: none; }
  .Movement_ModalForeground__1_rVW:hover {
    opacity: .85; }
    .Movement_ModalForeground__1_rVW:hover > .Movement_ModalHelpText__2bVM3 {
      display: block; }

.Movement_ModalActive__gkkYF {
  display: block; }

.Movement_ModalHelpText__2bVM3 {
  text-align: center;
  position: absolute;
  top: 35vh;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4.5rem;
  display: none; }

.Movement_CreateProposalWrapper__kqZWs {
  margin-bottom: 8rem;
  padding-top: 6rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 70rem;
  max-width: 100%;
  background-color: white;
  text-align: left;
  position: relative; }
  @media (min-width: 700px) {
    .Movement_CreateProposalWrapper__kqZWs {
      padding-top: 5rem;
      padding-left: 5rem;
      padding-right: 5rem; } }

.Movement_CreateHeader__2vHfH {
  margin-bottom: 2rem;
  font-size: 3.2rem;
  font-weight: 500;
  font-family: "Ubuntu", sans-serif;
  color: black;
  text-align: center; }

.Movement_CreateCloseButton__S4RF4 {
  color: black; }

.Movement_ActionComingSoonText__2vU8w {
  font-family: "VT323", monospace;
  color: #000000;
  font-size: 4rem; }

.Movement_DarkLabel__1OL14 > label {
  color: black; }

.Movement_ErrorMessage__1tijD {
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
  color: #ef495f;
  margin-top: 1rem;
  margin-bottom: -1rem; }

.Movement_MainProposalButton__2MlyT {
  display: block;
  transition: all .15s linear;
  border: 0.5rem solid #8b33e2;
  border-radius: 3.6rem;
  min-height: 8rem;
  width: 25rem;
  margin-top: 4rem;
  margin-bottom: 6rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: .4rem;
  padding-bottom: .4rem;
  color: #ffffff;
  background-color: #8b33e2;
  font-size: 3.2rem;
  font-weight: 600; }
  .Movement_MainProposalButton__2MlyT:hover {
    color: #000000;
    background-color: #ffffff; }

.Movement_ProposalTypeWrapper__IGfhB {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 6rem; }

.Movement_ProposalTypeButton__3A93V {
  width: 25rem;
  transition: all .15s linear;
  border: 0.5rem solid #8b33e2;
  border-radius: 1.2rem;
  min-height: 8rem;
  margin-top: 3.5rem;
  padding-top: .4rem;
  padding-bottom: .4rem;
  background-color: inherit;
  color: #000000;
  font-size: 3.2rem;
  font-weight: 600; }
  @media (min-width: 700px) {
    .Movement_ProposalTypeButton__3A93V {
      width: 38rem; } }
  .Movement_ProposalTypeButton__3A93V:hover {
    color: #ffffff;
    background-color: #711cc6;
    border: 0; }
  .Movement_ProposalTypeButton__3A93V.Movement_SelectedProposalType__pH-hR {
    color: #ffffff;
    background-color: #8b33e2;
    border-radius: 0;
    border: 0; }

.Movement_ProposalTypeSelector__w9g5A {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  border: 0.5rem #8b33e2 solid;
  border-radius: 1.5rem;
  max-width: 40rem; }

.Movement_SelectorButton__2aBKh {
  transition: all .15s linear;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: .8rem;
  width: 50%;
  color: black;
  text-align: center;
  font-size: 2.4rem;
  font-weight: 600; }
  .Movement_SelectorButton__2aBKh:hover {
    cursor: pointer; }

.Movement_ActiveSelectorButton__1gc6F {
  background-color: #8b33e2;
  color: white; }

.Movement_HiddenFields__1DcEk {
  display: none; }

.Movement_TextButton__3kthE {
  font-size: 3.6rem;
  text-decoration: underline;
  font-family: "VT323", monospace;
  color: #8b33e2;
  background: none; }
  .Movement_TextButton__3kthE:hover {
    cursor: pointer; }

.Movement_ManageSubmenu__1SCaC {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: -1.5rem;
  margin-left: 1rem;
  margin-right: 1rem; }

.Movement_MenuBreak__hcG0o {
  width: 100%;
  height: .1rem; }

.Movement_ManageSubmenuButton__jb1JN {
  background-color: inherit;
  color: #8b33e2;
  font-weight: 600; }
  .Movement_ManageSubmenuButton__jb1JN.Movement_SelectedButton__39hpQ {
    text-decoration: underline; }
  .Movement_ManageSubmenuButton__jb1JN:first-of-type {
    margin-left: 3rem; }
  .Movement_ManageSubmenuButton__jb1JN:last-of-type {
    margin-right: 3rem; }

.Movement_BankTypeSelector__IlYa6 {
  display: flex;
  border: 0.5rem #8b33e2 solid;
  border-radius: 1.5rem;
  max-width: 20rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.5rem; }
  .Movement_BankTypeSelector__IlYa6 > .Movement_SelectorButton__2aBKh {
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-size: 2rem; }

.Movement_TokenTitleRow__2fvpr {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem; }

.Movement_TokenNameTitle__LX-5p,
.Movement_TokenAmtTitle__YUEsq,
.Movement_TokenValueTitle__1EXNC,
.Movement_TokenTotalValueTitle__13BGC {
  white-space: nowrap;
  overflow: hidden;
  color: #000000;
  text-align: left;
  margin-right: 3rem;
  margin-bottom: 1rem;
  font-weight: 600; }

.Movement_TokenNameTitle__LX-5p,
.Movement_TokenAddress__1trnE {
  width: 30.5%; }

.Movement_TokenAmtTitle__YUEsq,
.Movement_TokenAmt__2mA_5 {
  width: 20%; }

.Movement_TokenValueTitle__1EXNC,
.Movement_TokenValue__2IlRZ {
  width: 20%; }

.Movement_TokenTotalValueTitle__13BGC,
.Movement_TokenTotalValue__2CRGr {
  width: 25%; }

.Movement_MemberNameTitle__3S4Sp,
.Movement_MemberSharesTitle__guljY,
.Movement_MemberLootTitle__3UO6H,
.Movement_MemberDepositsValueTitle__3baG2 {
  color: #000000;
  text-align: left;
  margin-right: 3rem;
  margin-bottom: -2rem;
  font-weight: 600; }

.Movement_MemberNameTitle__3S4Sp {
  width: 27.5%;
  margin-left: 1rem; }

.Movement_MemberSharesTitle__guljY,
.Movement_MemberLootTitle__3UO6H {
  width: 20%; }

.Movement_MemberSharesTitle__guljY {
  margin-right: 0rem; }

.Movement_MemberLootTitle__3UO6H {
  margin-left: 1rem;
  margin-right: -1rem; }

.Movement_MemberDepositsValueTitle__3baG2 {
  width: 25%;
  margin-right: 7rem;
  margin-left: 2rem; }

.Movement_Withdraw__2DGIW {
  text-align: left;
  margin-top: -.75rem;
  margin-left: -2.6%;
  margin-bottom: 1rem; }

.Movement_TokenRow__aU0-n {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem; }

.Movement_TokenName__1qviR,
.Movement_TokenAddress__1trnE,
.Movement_TokenAmt__2mA_5,
.Movement_TokenValue__2IlRZ,
.Movement_TokenTotalValue__2CRGr {
  font-size: 1.4rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #000000;
  text-align: left;
  padding-right: .75rem; }

.Movement_TokenName__1qviR {
  width: 100%;
  font-size: 1.6rem;
  font-weight: 600; }

.Movement_WithdrawalButton__1tqOB {
  color: #8b33e2;
  background: inherit;
  font-size: 1.3rem;
  margin-left: 1rem;
  text-decoration: underline; }

@keyframes NotificationService_fadeIn__1qZcq {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes NotificationService_fadeOut__3AVel {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.NotificationService_FadeIn__3DnvZ {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: NotificationService_fadeIn__1qZcq;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.NotificationService_FadeOut__2-mgt {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: NotificationService_fadeOut__3AVel;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.NotificationService_Flash__JjwxH {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: NotificationService_fadeIn__1qZcq, NotificationService_fadeOut__3AVel;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .NotificationService_Flash__JjwxH p {
    font-weight: 900; }

@keyframes NotificationService_fadeIn__1qZcq {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes NotificationService_fadeOut__3AVel {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.NotificationService_FadeIn__3DnvZ {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: NotificationService_fadeIn__1qZcq;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.NotificationService_FadeOut__2-mgt {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: NotificationService_fadeOut__3AVel;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.NotificationService_Flash__JjwxH {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: NotificationService_fadeIn__1qZcq, NotificationService_fadeOut__3AVel;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .NotificationService_Flash__JjwxH p {
    font-weight: 900; }

.NotificationService_react-datepicker__2oVF2 {
  font-size: 1em; }

.NotificationService_react-datepicker__header__3Kmlm {
  padding-top: 0.8em; }

.NotificationService_react-datepicker__month__AL8aG {
  margin: 0.4em 1em; }

.NotificationService_react-datepicker__day-name__3cYgc, .NotificationService_react-datepicker__day__3MIBO {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em; }

.NotificationService_react-datepicker__day--selected__31ahx {
  background-color: #8b33e2; }

.NotificationService_react-datepicker__current-month__1GzSP {
  font-size: 1em; }

.NotificationService_react-datepicker__navigation__26I0I {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent; }

.NotificationService_react-datepicker__navigation--previous__3Fw0d {
  border-top-color: #8b33e2;
  border-left-color: #8b33e2;
  border-bottom-color: #8b33e2;
  border-right-color: grey;
  left: .2em;
  box-shadow: none; }

.NotificationService_react-datepicker__navigation--next__3Y_nF {
  border-top-color: #8b33e2;
  border-right-color: #8b33e2;
  border-bottom-color: #8b33e2;
  border-left-color: grey;
  box-shadow: none;
  right: .2em; }

.NotificationService_Card__hHDE8, .NotificationService_BaseContainer__1H3AF, .NotificationService_PeepsContainer__1pAMY, .NotificationService_SuccessContainer__1UDt6, .NotificationService_ErrorContainer__3rY57 {
  box-sizing: border-box;
  overflow: hidden;
  background-color: #1a1a1a;
  border-radius: 4rem;
  border: 1rem solid #8b33e2;
  padding-top: 3.6rem;
  padding-bottom: 2.6rem;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: left; }

.NotificationService_ButtonRaised__zwGqN {
  font-family: "Muli", sans-serif;
  border-radius: 15px;
  box-shadow: 2px, 2px; }

.NotificationService_InputRounded__1P--y {
  min-width: 8.9rem;
  padding: 0.5rem;
  min-height: 1rem;
  box-sizing: border-box;
  border: 1px solid #d6d6d6;
  outline: none;
  border: none;
  background-color: white;
  border: solid #d6d6d6 1px;
  min-width: 300px;
  max-width: 100%;
  font-size: 21px;
  padding: 20px 20px 10px 20px;
  border-radius: 35px; }

.NotificationService_BaseContainer__1H3AF, .NotificationService_PeepsContainer__1pAMY, .NotificationService_SuccessContainer__1UDt6 {
  background-color: white; }

.NotificationService_BaseBody__2QvuP, .NotificationService_ErrorBody__8dGIY, .NotificationService_SuccessBody__3nObx {
  color: #8b33e2;
  font-family: "VT323", monospace; }

.NotificationService_ErrorContainer__3rY57 {
  background-color: white;
  font-family: "VT323", monospace;
  color: red; }

.NotificationService_ErrorBody__8dGIY {
  color: #000000; }

.NotificationService_SuccessContainer__1UDt6 {
  background-color: #2aaf3a; }

.NotificationService_SuccessBody__3nObx {
  color: #000000; }

.NotificationService_ProgressBar__2VtpP {
  background: #555555; }

@keyframes Input_fadeIn__2bgyn {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes Input_fadeOut__AorX- {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.Input_FadeIn__2LsC4 {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Input_fadeIn__2bgyn;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Input_FadeOut__S8jNK {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Input_fadeOut__AorX-;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Input_Flash__2MYmK {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Input_fadeIn__2bgyn, Input_fadeOut__AorX-;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .Input_Flash__2MYmK p {
    font-weight: 900; }

@keyframes Input_fadeIn__2bgyn {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes Input_fadeOut__AorX- {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.Input_FadeIn__2LsC4 {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Input_fadeIn__2bgyn;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Input_FadeOut__S8jNK {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Input_fadeOut__AorX-;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Input_Flash__2MYmK {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Input_fadeIn__2bgyn, Input_fadeOut__AorX-;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .Input_Flash__2MYmK p {
    font-weight: 900; }

.Input_react-datepicker__sc9nc {
  font-size: 1em; }

.Input_react-datepicker__header__1rVDK {
  padding-top: 0.8em; }

.Input_react-datepicker__month__3VoT2 {
  margin: 0.4em 1em; }

.Input_react-datepicker__day-name__vWdbA, .Input_react-datepicker__day__2AB0Z {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em; }

.Input_react-datepicker__day--selected__Z29pJ {
  background-color: #8b33e2; }

.Input_react-datepicker__current-month__2WL3N {
  font-size: 1em; }

.Input_react-datepicker__navigation__1MjRb {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent; }

.Input_react-datepicker__navigation--previous__2WUmt {
  border-top-color: #8b33e2;
  border-left-color: #8b33e2;
  border-bottom-color: #8b33e2;
  border-right-color: grey;
  left: .2em;
  box-shadow: none; }

.Input_react-datepicker__navigation--next__3VUDi {
  border-top-color: #8b33e2;
  border-right-color: #8b33e2;
  border-bottom-color: #8b33e2;
  border-left-color: grey;
  box-shadow: none;
  right: .2em; }

.Input_Card__2IYt_ {
  box-sizing: border-box;
  overflow: hidden;
  background-color: #1a1a1a;
  border-radius: 4rem;
  border: 1rem solid #8b33e2;
  padding-top: 3.6rem;
  padding-bottom: 2.6rem;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: left; }

.Input_ButtonRaised__1jQVv {
  font-family: "Muli", sans-serif;
  border-radius: 15px;
  box-shadow: 2px, 2px; }

.Input_InputRounded__1eNP1 {
  min-width: 8.9rem;
  padding: 0.5rem;
  min-height: 1rem;
  box-sizing: border-box;
  border: 1px solid #d6d6d6;
  outline: none;
  border: none;
  background-color: white;
  border: solid #d6d6d6 1px;
  min-width: 300px;
  max-width: 100%;
  font-size: 21px;
  padding: 20px 20px 10px 20px;
  border-radius: 35px; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.Input_InputContainer__VbQxi {
  width: 100%;
  margin: 2.5rem 0rem; }

.Input_InputLabel__2hxrR {
  display: inline-block;
  font-size: 2rem;
  padding-left: .7rem;
  text-align: left;
  color: white;
  font-weight: 500;
  font-family: "Ubuntu", sans-serif; }

.Input_InputRounded__1eNP1 {
  padding: 0.5rem;
  margin-top: 1rem;
  min-height: 1rem;
  box-sizing: border-box;
  border: .1rem solid #d6d6d6;
  outline: none;
  border: none;
  background-color: white;
  border: solid #d6d6d6 .1rem;
  min-width: 30rem;
  font-size: 2.5rem;
  padding: 2rem 2rem 1rem 2rem;
  border-radius: 1.5rem;
  font-family: "VT323", monospace; }

.Input_InputLarge__2gchL {
  padding: 1.4rem;
  color: #000000;
  width: 100%;
  min-width: 100%;
  min-height: 5rem; }

.Input_TextArea__2gw13 {
  min-height: 14rem; }

@keyframes IconButton_fadeIn__1nUlb {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes IconButton_fadeOut__1zbqx {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.IconButton_FadeIn__38sqb {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: IconButton_fadeIn__1nUlb;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.IconButton_FadeOut__3scNu {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: IconButton_fadeOut__1zbqx;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.IconButton_Flash__19dJT {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: IconButton_fadeIn__1nUlb, IconButton_fadeOut__1zbqx;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .IconButton_Flash__19dJT p {
    font-weight: 900; }

.IconButton_IconButton__2AQBE {
  padding: 0;
  background: 0;
  border-radius: 5rem; }
  .IconButton_IconButton__2AQBE:focus {
    outline: 0;
    text-decoration: underline; }
  .IconButton_IconButton__2AQBE > .IconButton_Emoji__1egvp {
    outline: 0; }

@keyframes VotingBar_fadeIn__2V1Bi {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes VotingBar_fadeOut__1_VaA {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.VotingBar_FadeIn__3wf9B {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: VotingBar_fadeIn__2V1Bi;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.VotingBar_FadeOut__vQj5k {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: VotingBar_fadeOut__1_VaA;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.VotingBar_Flash__16-2M {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: VotingBar_fadeIn__2V1Bi, VotingBar_fadeOut__1_VaA;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .VotingBar_Flash__16-2M p {
    font-weight: 900; }

@keyframes VotingBar_fadeIn__2V1Bi {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes VotingBar_fadeOut__1_VaA {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.VotingBar_FadeIn__3wf9B {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: VotingBar_fadeIn__2V1Bi;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.VotingBar_FadeOut__vQj5k {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: VotingBar_fadeOut__1_VaA;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.VotingBar_Flash__16-2M {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: VotingBar_fadeIn__2V1Bi, VotingBar_fadeOut__1_VaA;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .VotingBar_Flash__16-2M p {
    font-weight: 900; }

.VotingBar_react-datepicker__WFO2u {
  font-size: 1em; }

.VotingBar_react-datepicker__header__3GkdP {
  padding-top: 0.8em; }

.VotingBar_react-datepicker__month__9hGxS {
  margin: 0.4em 1em; }

.VotingBar_react-datepicker__day-name__3TPNu, .VotingBar_react-datepicker__day__3EKPR {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em; }

.VotingBar_react-datepicker__day--selected__2Lfc- {
  background-color: #8b33e2; }

.VotingBar_react-datepicker__current-month__1x9YS {
  font-size: 1em; }

.VotingBar_react-datepicker__navigation__2N_M6 {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent; }

.VotingBar_react-datepicker__navigation--previous__2FGRa {
  border-top-color: #8b33e2;
  border-left-color: #8b33e2;
  border-bottom-color: #8b33e2;
  border-right-color: grey;
  left: .2em;
  box-shadow: none; }

.VotingBar_react-datepicker__navigation--next__3oEMP {
  border-top-color: #8b33e2;
  border-right-color: #8b33e2;
  border-bottom-color: #8b33e2;
  border-left-color: grey;
  box-shadow: none;
  right: .2em; }

.VotingBar_Card__2lrdK {
  box-sizing: border-box;
  overflow: hidden;
  background-color: #1a1a1a;
  border-radius: 4rem;
  border: 1rem solid #8b33e2;
  padding-top: 3.6rem;
  padding-bottom: 2.6rem;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: left; }

.VotingBar_ButtonRaised__Fpuux {
  font-family: "Muli", sans-serif;
  border-radius: 15px;
  box-shadow: 2px, 2px; }

.VotingBar_InputRounded__3oftC {
  min-width: 8.9rem;
  padding: 0.5rem;
  min-height: 1rem;
  box-sizing: border-box;
  border: 1px solid #d6d6d6;
  outline: none;
  border: none;
  background-color: white;
  border: solid #d6d6d6 1px;
  min-width: 300px;
  max-width: 100%;
  font-size: 21px;
  padding: 20px 20px 10px 20px;
  border-radius: 35px; }

.VotingBar_VotesMeterContainer__hCgw0 {
  position: relative;
  z-index: 3;
  margin-top: 3.2rem;
  margin-left: -.3rem;
  margin-right: -.3rem;
  width: 25rem;
  height: 2.0rem;
  background-color: white;
  border: .1rem solid black;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.VotingBar_VotesMeter__c9uzf {
  background-color: #8b33e2;
  height: 2.3rem;
  border-left: .1rem solid black; }

@keyframes CardNav_fadeIn__4iUgR {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes CardNav_fadeOut__QHvNZ {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.CardNav_FadeIn__2Bw46 {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: CardNav_fadeIn__4iUgR;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.CardNav_FadeOut__39OcB {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: CardNav_fadeOut__QHvNZ;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.CardNav_Flash__2WE4_ {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: CardNav_fadeIn__4iUgR, CardNav_fadeOut__QHvNZ;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .CardNav_Flash__2WE4_ p {
    font-weight: 900; }

@keyframes CardNav_fadeIn__4iUgR {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes CardNav_fadeOut__QHvNZ {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.CardNav_FadeIn__2Bw46 {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: CardNav_fadeIn__4iUgR;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.CardNav_FadeOut__39OcB {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: CardNav_fadeOut__QHvNZ;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.CardNav_Flash__2WE4_ {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: CardNav_fadeIn__4iUgR, CardNav_fadeOut__QHvNZ;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .CardNav_Flash__2WE4_ p {
    font-weight: 900; }

.CardNav_NavContainer__2KV__ {
  display: flex;
  justify-content: space-around;
  margin-bottom: 4rem; }

.CardNav_NavItem__1xeut {
  font-size: 3rem;
  color: #000000; }
  @media (min-width: 700px) {
    .CardNav_NavItem__1xeut {
      font-size: 3.8rem; } }
  .CardNav_NavItem__1xeut:hover {
    cursor: pointer; }
  .CardNav_NavItem__1xeut.CardNav_Selected__3u5GK {
    text-decoration: underline; }

@keyframes Loading_fadeIn__23ylq {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes Loading_fadeOut__12RN3 {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.Loading_FadeIn__2tHGA {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Loading_fadeIn__23ylq;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Loading_FadeOut__2we0Q {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Loading_fadeOut__12RN3;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Loading_Flash__2ZS87 {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Loading_fadeIn__23ylq, Loading_fadeOut__12RN3;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .Loading_Flash__2ZS87 p {
    font-weight: 900; }

.Loading_Loading__1WyPN {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 10rem;
  left: 0;
  background: #1a1a1a;
  display: block;
  z-index: 10; }
  .Loading_Loading__1WyPN svg {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    width: 8rem;
    height: 8rem; }

@keyframes DAOCard_fadeIn__2AEQy {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes DAOCard_fadeOut__ZCtSu {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.DAOCard_FadeIn__GkVD4 {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: DAOCard_fadeIn__2AEQy;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.DAOCard_FadeOut__1P69p {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: DAOCard_fadeOut__ZCtSu;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.DAOCard_Flash__yLf4M {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: DAOCard_fadeIn__2AEQy, DAOCard_fadeOut__ZCtSu;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .DAOCard_Flash__yLf4M p {
    font-weight: 900; }

@keyframes DAOCard_fadeIn__2AEQy {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes DAOCard_fadeOut__ZCtSu {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.DAOCard_FadeIn__GkVD4 {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: DAOCard_fadeIn__2AEQy;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.DAOCard_FadeOut__1P69p {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: DAOCard_fadeOut__ZCtSu;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.DAOCard_Flash__yLf4M {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: DAOCard_fadeIn__2AEQy, DAOCard_fadeOut__ZCtSu;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .DAOCard_Flash__yLf4M p {
    font-weight: 900; }

.DAOCard_react-datepicker__12rZa {
  font-size: 1em; }

.DAOCard_react-datepicker__header__2yLT3 {
  padding-top: 0.8em; }

.DAOCard_react-datepicker__month__28Xn5 {
  margin: 0.4em 1em; }

.DAOCard_react-datepicker__day-name__3i3Oy, .DAOCard_react-datepicker__day__3LpAh {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em; }

.DAOCard_react-datepicker__day--selected__1RjZh {
  background-color: #8b33e2; }

.DAOCard_react-datepicker__current-month__3WGUL {
  font-size: 1em; }

.DAOCard_react-datepicker__navigation__18m2S {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent; }

.DAOCard_react-datepicker__navigation--previous__3dCzp {
  border-top-color: #8b33e2;
  border-left-color: #8b33e2;
  border-bottom-color: #8b33e2;
  border-right-color: grey;
  left: .2em;
  box-shadow: none; }

.DAOCard_react-datepicker__navigation--next__3bn-t {
  border-top-color: #8b33e2;
  border-right-color: #8b33e2;
  border-bottom-color: #8b33e2;
  border-left-color: grey;
  box-shadow: none;
  right: .2em; }

.DAOCard_Card__dgk-2, .DAOCard_MainCard__296uI {
  box-sizing: border-box;
  overflow: hidden;
  background-color: #1a1a1a;
  border-radius: 4rem;
  border: 1rem solid #8b33e2;
  padding-top: 3.6rem;
  padding-bottom: 2.6rem;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: left; }

.DAOCard_ButtonRaised__2Ugjw {
  font-family: "Muli", sans-serif;
  border-radius: 15px;
  box-shadow: 2px, 2px; }

.DAOCard_InputRounded__1onzM {
  min-width: 8.9rem;
  padding: 0.5rem;
  min-height: 1rem;
  box-sizing: border-box;
  border: 1px solid #d6d6d6;
  outline: none;
  border: none;
  background-color: white;
  border: solid #d6d6d6 1px;
  min-width: 300px;
  max-width: 100%;
  font-size: 21px;
  padding: 20px 20px 10px 20px;
  border-radius: 35px; }

.DAOCard_MainCard__296uI {
  transition: all .15s linear;
  max-width: 40rem;
  min-height: 40rem;
  margin-bottom: 7rem;
  padding-right: .5rem;
  display: flex;
  flex-flow: column; }
  .DAOCard_MainCard__296uI:hover {
    background-color: #ffffff; }
    .DAOCard_MainCard__296uI:hover > .DAOCard_TitleText__2TgWK,
    .DAOCard_MainCard__296uI:hover .DAOCard_DescriptionText__21Rut,
    .DAOCard_MainCard__296uI:hover .DAOCard_CardStats__CNSV-,
    .DAOCard_MainCard__296uI:hover .DAOCard_StatText__11PDX {
      color: #000000; }

.DAOCard_TitleText__2TgWK {
  margin-bottom: 2.4rem; }

.DAOCard_DescriptionText__21Rut {
  margin-bottom: 2.6rem;
  font-size: 2.4rem; }

.DAOCard_CardStats__CNSV- {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start; }

.DAOCard_StatText__11PDX {
  width: 50%;
  margin-bottom: 1.8rem;
  font-size: 2.4rem;
  line-height: 2.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.DAOCard_NewTag__6pvt7 {
  float: right;
  background: red;
  border-radius: 10rem;
  min-width: 20%;
  min-height: 2.5rem;
  text-align: center;
  align-content: left;
  font-weight: bolder;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-top: -2rem;
  z-index: 1; }

@keyframes Discover_fadeIn__2sW2E {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes Discover_fadeOut__HT8zU {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.Discover_FadeIn__pSSoU {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Discover_fadeIn__2sW2E;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Discover_FadeOut__3crSm {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Discover_fadeOut__HT8zU;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Discover_Flash__2XfV6 {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Discover_fadeIn__2sW2E, Discover_fadeOut__HT8zU;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .Discover_Flash__2XfV6 p {
    font-weight: 900; }

@keyframes Discover_fadeIn__2sW2E {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes Discover_fadeOut__HT8zU {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.Discover_FadeIn__pSSoU {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Discover_fadeIn__2sW2E;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Discover_FadeOut__3crSm {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Discover_fadeOut__HT8zU;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Discover_Flash__2XfV6 {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Discover_fadeIn__2sW2E, Discover_fadeOut__HT8zU;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .Discover_Flash__2XfV6 p {
    font-weight: 900; }

.Discover_MainContent__3xqbu {
  padding-top: 2rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-flow: column;
  text-align: center; }
  @media (min-width: 700px) {
    .Discover_MainContent__3xqbu {
      padding-top: 5rem;
      padding-bottom: 3rem; } }

.Discover_DAOsContainer__3iBkm {
  max-width: 50rem;
  min-width: 0;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 900px) {
    .Discover_DAOsContainer__3iBkm {
      max-width: 100rem;
      min-width: 90rem; } }

.Discover_PageHeader__6uKvL {
  font-size: 4rem;
  margin-bottom: 2rem; }
  @media (min-width: 700px) {
    .Discover_PageHeader__6uKvL {
      font-size: 7rem; } }

.Discover_PageSubHeader__11Hty {
  font-size: 1.5rem;
  max-width: 60rem;
  font-family: "Ubuntu", sans-serif;
  margin-top: .5rem;
  margin-bottom: 6rem;
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 700px) {
    .Discover_PageSubHeader__11Hty {
      font-size: 3rem; } }

@keyframes CreateDAO_fadeIn__2pnBv {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes CreateDAO_fadeOut__1pGS8 {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.CreateDAO_FadeIn__3S-sl {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: CreateDAO_fadeIn__2pnBv;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.CreateDAO_FadeOut__15ouD {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: CreateDAO_fadeOut__1pGS8;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.CreateDAO_Flash__1cElp {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: CreateDAO_fadeIn__2pnBv, CreateDAO_fadeOut__1pGS8;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .CreateDAO_Flash__1cElp p {
    font-weight: 900; }

@keyframes CreateDAO_fadeIn__2pnBv {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes CreateDAO_fadeOut__1pGS8 {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.CreateDAO_FadeIn__3S-sl {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: CreateDAO_fadeIn__2pnBv;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.CreateDAO_FadeOut__15ouD {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: CreateDAO_fadeOut__1pGS8;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.CreateDAO_Flash__1cElp {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: CreateDAO_fadeIn__2pnBv, CreateDAO_fadeOut__1pGS8;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .CreateDAO_Flash__1cElp p {
    font-weight: 900; }

.CreateDAO_CreateWrapper__IdWDq {
  max-width: 110rem;
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center; }

.CreateDAO_PageHeader__BSzGP {
  font-size: 4rem;
  margin-bottom: 2rem;
  text-align: center; }
  @media (min-width: 700px) {
    .CreateDAO_PageHeader__BSzGP {
      font-size: 6rem; } }

.CreateDAO_PageSubHeader__28uY5 {
  font-size: 3rem;
  margin-top: 2rem;
  margin-bottom: 4rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }
  @media (min-width: 700px) {
    .CreateDAO_PageSubHeader__28uY5 {
      font-size: 4.8rem; } }

.CreateDAO_CreateForm__1tITM {
  width: 96rem;
  max-width: 100%;
  text-align: left;
  margin-bottom: 4rem; }

.CreateDAO_CategoryList__1Ciqj {
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem; }
  @media (min-width: 700px) {
    .CreateDAO_CategoryList__1Ciqj {
      flex-direction: row;
      align-items: flex-start; } }

.CreateDAO_CategoryButton__2C45g {
  font-size: 3rem;
  font-family: "VT323", monospace;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 25rem;
  background: none;
  color: white;
  margin-left: 0;
  margin-right: 0;
  margin-top: 2rem;
  margin-bottom: 2rem; }
  @media (min-width: 700px) {
    .CreateDAO_CategoryButton__2C45g {
      font-size: 4.8rem;
      margin-left: 2rem;
      margin-right: 2rem; } }

.CreateDAO_CategoryImg__2x5Y8 {
  max-width: 25rem;
  border-radius: 2rem; }

.CreateDAO_ButtonRow__3HH9d {
  width: 100%;
  display: flex;
  justify-content: space-between; }

.CreateDAO_MainButton__DxSJR, .CreateDAO_SecondaryButton__37e2u {
  transition: all .15s linear;
  border: 0.5rem solid #8b33e2;
  border-radius: 3.6rem;
  min-height: 8rem;
  width: 35rem;
  margin-right: 1.5rem;
  margin-top: 2rem;
  padding-top: .4rem;
  padding-bottom: .4rem;
  color: #ffffff;
  background-color: #8b33e2;
  font-size: 3.2rem;
  font-weight: 600; }
  .CreateDAO_MainButton__DxSJR:last-of-type, .CreateDAO_SecondaryButton__37e2u:last-of-type {
    margin-right: 0; }
  .CreateDAO_MainButton__DxSJR:hover, .CreateDAO_SecondaryButton__37e2u:hover {
    color: #000000;
    background-color: #ffffff; }

.CreateDAO_SecondaryButton__37e2u {
  color: #000000;
  background-color: #ffffff; }
  .CreateDAO_SecondaryButton__37e2u:hover {
    color: #ffffff;
    background-color: #8b33e2; }

.CreateDAO_InputRow__AH_IE {
  display: flex;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }
  .CreateDAO_InputRow__AH_IE > div {
    margin-top: 0;
    margin-bottom: 0; }

.CreateDAO_ThirdWidth__1-Qgx {
  width: calc(40% - 1.5rem); }
  .CreateDAO_ThirdWidth__1-Qgx:first-of-type {
    margin-right: 1.5rem; }

.CreateDAO_HalfWidth__20mJm {
  width: calc(50% - .75rem); }
  .CreateDAO_HalfWidth__20mJm:first-of-type {
    margin-right: 1.5rem; }

.CreateDAO_TwoThirdWidth__3ktct {
  width: calc(60% - 1rem);
  margin-left: 1rem; }

.CreateDAO_ErrorMessage__1iEu0 {
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
  color: #ef495f; }

.CreateDAO_CreateInputFields__2Egiv {
  max-width: 75rem;
  margin: auto; }

.CreateDAO_HiddenFields__2MKPv {
  display: none; }

.CreateDAO_CreateTokenList__3PLIF {
  max-width: 75rem;
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }

.CreateDAO_TokenOption__1SuLa, .CreateDAO_CustomTokenOption__31RC4 {
  max-width: calc(50% - 2rem);
  width: 32rem;
  margin-left: 2rem;
  margin-right: 2rem;
  display: flex;
  margin-bottom: 4rem; }

.CreateDAO_CustomTokenOption__31RC4 {
  max-width: 100%;
  width: 64rem; }
  .CreateDAO_CustomTokenOption__31RC4 > .CreateDAO_TokenCheckboxLabel__XSNh5 {
    width: 100%;
    padding-top: 1rem; }
    .CreateDAO_CustomTokenOption__31RC4 > .CreateDAO_TokenCheckboxLabel__XSNh5 > .CreateDAO_TokenAddress__1gatJ {
      max-width: 100%;
      white-space: normal;
      overflow: auto;
      text-overflow: inherit; }
      .CreateDAO_CustomTokenOption__31RC4 > .CreateDAO_TokenCheckboxLabel__XSNh5 > .CreateDAO_TokenAddress__1gatJ > div {
        margin-top: -2rem; }

.CreateDAO_TokenCheckbox__1I1PW {
  -webkit-appearance: none;
  width: 3.5rem;
  height: 3.5rem;
  margin-top: .8rem;
  margin-right: 2.5rem;
  background: white;
  border-radius: 1.2rem;
  border: .4rem solid white; }
  .CreateDAO_TokenCheckbox__1I1PW:checked {
    background: #010101; }
  .CreateDAO_TokenCheckbox__1I1PW:hover {
    cursor: pointer; }

.CreateDAO_TokenCheckboxLabel__XSNh5:hover {
  cursor: pointer; }

.CreateDAO_TokenName__2NM4- {
  font-size: 3rem; }

.CreateDAO_TokenAddress__1gatJ {
  font-size: 1.3rem;
  max-width: 25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.CreateDAO_AddPeepsButtonsWrapper__2axOt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem; }

.CreateDAO_CreateTextButton__20y08 {
  font-size: 3.6rem;
  text-decoration: underline;
  font-family: "VT323", monospace;
  color: #8b33e2;
  background: none; }
  .CreateDAO_CreateTextButton__20y08:hover {
    cursor: pointer; }

.CreateDAO_CsvReaderInput__VOa0E {
  display: none; }

@keyframes Faq_fadeIn__1OlLc {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes Faq_fadeOut__39Bt5 {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.Faq_FadeIn__ggFV6 {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Faq_fadeIn__1OlLc;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Faq_FadeOut__hkTTq {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Faq_fadeOut__39Bt5;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Faq_Flash__22zj9 {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Faq_fadeIn__1OlLc, Faq_fadeOut__39Bt5;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .Faq_Flash__22zj9 p {
    font-weight: 900; }

@keyframes Faq_fadeIn__1OlLc {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes Faq_fadeOut__39Bt5 {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.Faq_FadeIn__ggFV6 {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Faq_fadeIn__1OlLc;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Faq_FadeOut__hkTTq {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Faq_fadeOut__39Bt5;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Faq_Flash__22zj9 {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Faq_fadeIn__1OlLc, Faq_fadeOut__39Bt5;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .Faq_Flash__22zj9 p {
    font-weight: 900; }

.Faq_react-datepicker__Yu1Ee {
  font-size: 1em; }

.Faq_react-datepicker__header__2fLw8 {
  padding-top: 0.8em; }

.Faq_react-datepicker__month__3VGa_ {
  margin: 0.4em 1em; }

.Faq_react-datepicker__day-name__2FASA, .Faq_react-datepicker__day__KoVqg {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em; }

.Faq_react-datepicker__day--selected__1fQzi {
  background-color: #8b33e2; }

.Faq_react-datepicker__current-month__3HqLR {
  font-size: 1em; }

.Faq_react-datepicker__navigation__Y-CHX {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent; }

.Faq_react-datepicker__navigation--previous__3ZmNm {
  border-top-color: #8b33e2;
  border-left-color: #8b33e2;
  border-bottom-color: #8b33e2;
  border-right-color: grey;
  left: .2em;
  box-shadow: none; }

.Faq_react-datepicker__navigation--next__2ayYG {
  border-top-color: #8b33e2;
  border-right-color: #8b33e2;
  border-bottom-color: #8b33e2;
  border-left-color: grey;
  box-shadow: none;
  right: .2em; }

.Faq_Card__2M9nZ, .Faq_ContainerMain__ZnqiB {
  box-sizing: border-box;
  overflow: hidden;
  background-color: #1a1a1a;
  border-radius: 4rem;
  border: 1rem solid #8b33e2;
  padding-top: 3.6rem;
  padding-bottom: 2.6rem;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: left; }

.Faq_ButtonRaised__2BTVY {
  font-family: "Muli", sans-serif;
  border-radius: 15px;
  box-shadow: 2px, 2px; }

.Faq_InputRounded__1oSp5 {
  min-width: 8.9rem;
  padding: 0.5rem;
  min-height: 1rem;
  box-sizing: border-box;
  border: 1px solid #d6d6d6;
  outline: none;
  border: none;
  background-color: white;
  border: solid #d6d6d6 1px;
  min-width: 300px;
  max-width: 100%;
  font-size: 21px;
  padding: 20px 20px 10px 20px;
  border-radius: 35px; }

.Faq_Faq__2qix5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 8rem; }

.Faq_ContainerMain__ZnqiB {
  min-height: 50vh;
  width: 80vw;
  max-width: 80rem;
  min-width: 30rem;
  display: flex;
  flex-direction: column;
  color: #555555;
  padding: 2.4rem;
  background-color: white;
  margin-top: 2rem;
  margin-bottom: 4rem; }

.Faq_CardTitle__1BRhR {
  margin: 3rem 0 0.5rem;
  font-size: 4.2rem;
  font-weight: 700;
  word-wrap: break-word;
  color: #8b33e2;
  text-align: center; }

.Faq_SubHeader__3agWh {
  margin: 3rem 0.5rem 0.5rem 0.5rem;
  font-size: 2rem;
  font-weight: bold;
  color: black; }

.Faq_Text__dfJHP {
  margin: 1rem 0.5rem 1rem 0.5rem;
  font-size: 1.75rem;
  font-weight: normal;
  text-align: left;
  color: black; }

@keyframes MyProfile_fadeIn__12Ggn {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes MyProfile_fadeOut__ofL1v {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.MyProfile_FadeIn__19ARY {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: MyProfile_fadeIn__12Ggn;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.MyProfile_FadeOut__TsVLf {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: MyProfile_fadeOut__ofL1v;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.MyProfile_Flash__3RpPG {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: MyProfile_fadeIn__12Ggn, MyProfile_fadeOut__ofL1v;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .MyProfile_Flash__3RpPG p {
    font-weight: 900; }

@keyframes MyProfile_fadeIn__12Ggn {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes MyProfile_fadeOut__ofL1v {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.MyProfile_FadeIn__19ARY {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: MyProfile_fadeIn__12Ggn;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.MyProfile_FadeOut__TsVLf {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: MyProfile_fadeOut__ofL1v;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.MyProfile_Flash__3RpPG {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: MyProfile_fadeIn__12Ggn, MyProfile_fadeOut__ofL1v;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .MyProfile_Flash__3RpPG p {
    font-weight: 900; }

.MyProfile_MainContent__3Nxso {
  max-width: 50rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: left; }
  @media (min-width: 700px) {
    .MyProfile_MainContent__3Nxso {
      padding-top: 5rem;
      padding-bottom: 3rem; } }
  @media (min-width: 900px) {
    .MyProfile_MainContent__3Nxso {
      max-width: 90rem; } }

.MyProfile_HeaderSection__1hxSJ {
  width: 100%; }

.MyProfile_PageHeader__2ltSJ {
  font-size: 4rem;
  line-height: 4rem; }
  @media (min-width: 700px) {
    .MyProfile_PageHeader__2ltSJ {
      font-size: 6.8rem;
      line-height: 6.8rem; } }

.MyProfile_PageSubHeader__g2-qv {
  font-size: 1.5rem;
  line-height: 1.5rem;
  max-width: 60rem;
  margin-bottom: 5rem; }
  @media (min-width: 700px) {
    .MyProfile_PageSubHeader__g2-qv {
      font-size: 3.4rem;
      line-height: 3.4rem; } }

.MyProfile_UserStatsContainer__7vr_t {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border: .5rem white solid;
  border-radius: 1rem;
  margin-bottom: 4.5rem;
  padding-top: 4rem;
  padding-bottom: 2rem; }
  @media (min-width: 700px) {
    .MyProfile_UserStatsContainer__7vr_t {
      flex-direction: row; } }

.MyProfile_UserStat__3pPW_ {
  font-size: 4.8rem;
  line-height: 4.8rem;
  text-align: center;
  margin-bottom: 2.5rem; }

.MyProfile_StatLabel__1UVbo {
  font-size: 3.6rem;
  line-height: 3.6rem; }

.MyProfile_DAOsContainer__132C9 {
  max-width: 50rem;
  min-width: 0;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 900px) {
    .MyProfile_DAOsContainer__132C9 {
      max-width: 100rem;
      min-width: 90rem; } }

.community-board-body {
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5rem;
  width: 80%;
  max-width: 120rem;
  border-radius: 1.25rem;
  background-color: white;
  color: black; }

.PageTitle {
  width: 100%;
  font-size: 5rem;
  line-height: 6rem;
  font-weight: 600;
  color: #8B33E2;
  text-align: center; }

.Update {
  font-size: 2rem;
  color: red;
  text-align: center; }

.community-board-explanation-text {
  font-style: normal;
  font-weight: 500;
  line-height: 2.5rem;
  font-size: 1.7rem;
  letter-spacing: 0.05em;
  margin-left: 4rem;
  margin-right: 4rem;
  margin-top: 4rem;
  color: black; }
  .community-board-explanation-text > p {
    color: black; }

.BioDescriptionTitle {
  margin: 1rem 0 .5rem;
  font-weight: 600;
  color: #8B33E2;
  font-size: 2.5rem; }

.italics {
  font-style: italic; }

.table-header {
  height: 1rem; }

.table,
.th,
.td {
  text-align: center;
  border: .1rem solid gray;
  border-collapse: collapse; }

.td,
.th {
  padding: 0;
  text-align: center;
  border: .1rem solid gray; }

@keyframes TopNav_fadeIn__2J1n6 {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes TopNav_fadeOut__3SLm6 {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.TopNav_FadeIn__6l81h {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: TopNav_fadeIn__2J1n6;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.TopNav_FadeOut__2Z7FM {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: TopNav_fadeOut__3SLm6;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.TopNav_Flash__3Yx3m {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: TopNav_fadeIn__2J1n6, TopNav_fadeOut__3SLm6;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .TopNav_Flash__3Yx3m p {
    font-weight: 900; }

@keyframes TopNav_fadeIn__2J1n6 {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes TopNav_fadeOut__3SLm6 {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.TopNav_FadeIn__6l81h {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: TopNav_fadeIn__2J1n6;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.TopNav_FadeOut__2Z7FM {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: TopNav_fadeOut__3SLm6;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.TopNav_Flash__3Yx3m {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: TopNav_fadeIn__2J1n6, TopNav_fadeOut__3SLm6;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .TopNav_Flash__3Yx3m p {
    font-weight: 900; }

.TopNav_react-datepicker__1hV3- {
  font-size: 1em; }

.TopNav_react-datepicker__header__2qVOV {
  padding-top: 0.8em; }

.TopNav_react-datepicker__month__TOoYD {
  margin: 0.4em 1em; }

.TopNav_react-datepicker__day-name__2L_rY, .TopNav_react-datepicker__day__3SVoi {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em; }

.TopNav_react-datepicker__day--selected__1Q7Dn {
  background-color: #8b33e2; }

.TopNav_react-datepicker__current-month__gzp6Y {
  font-size: 1em; }

.TopNav_react-datepicker__navigation__j14jW {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent; }

.TopNav_react-datepicker__navigation--previous__3O5bp {
  border-top-color: #8b33e2;
  border-left-color: #8b33e2;
  border-bottom-color: #8b33e2;
  border-right-color: grey;
  left: .2em;
  box-shadow: none; }

.TopNav_react-datepicker__navigation--next__1yOu5 {
  border-top-color: #8b33e2;
  border-right-color: #8b33e2;
  border-bottom-color: #8b33e2;
  border-left-color: grey;
  box-shadow: none;
  right: .2em; }

.TopNav_Card__2eUaR, .TopNav_ModalContainer___xFDO {
  box-sizing: border-box;
  overflow: hidden;
  background-color: #1a1a1a;
  border-radius: 4rem;
  border: 1rem solid #8b33e2;
  padding-top: 3.6rem;
  padding-bottom: 2.6rem;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: left; }

.TopNav_ButtonRaised__MC6Qv {
  font-family: "Muli", sans-serif;
  border-radius: 15px;
  box-shadow: 2px, 2px; }

.TopNav_InputRounded__2oNPj {
  min-width: 8.9rem;
  padding: 0.5rem;
  min-height: 1rem;
  box-sizing: border-box;
  border: 1px solid #d6d6d6;
  outline: none;
  border: none;
  background-color: white;
  border: solid #d6d6d6 1px;
  min-width: 300px;
  max-width: 100%;
  font-size: 21px;
  padding: 20px 20px 10px 20px;
  border-radius: 35px; }

@keyframes TopNav_fadeIn__2J1n6 {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes TopNav_fadeOut__3SLm6 {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.TopNav_FadeIn__6l81h {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: TopNav_fadeIn__2J1n6;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.TopNav_FadeOut__2Z7FM {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: TopNav_fadeOut__3SLm6;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.TopNav_Flash__3Yx3m {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: TopNav_fadeIn__2J1n6, TopNav_fadeOut__3SLm6;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .TopNav_Flash__3Yx3m p {
    font-weight: 900; }

.TopNav_TopNav__3ncYe {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font: "VT323", monospace;
  width: 100%;
  height: 8.4rem;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem; }

.TopNav_LeftNav__HHjpV,
.TopNav_RightNav__3jaVP {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 60%; }

.TopNav_TokenNav__-3ySG {
  align-items: flex-start;
  margin-left: 16rem;
  margin-right: 1rem; }

.TopNav_LeftNav__HHjpV:focus {
  outline: 0;
  text-decoration: underline; }

.TopNav_BrandImg__2bLMc {
  display: none; }
  @media (min-width: 700px) {
    .TopNav_BrandImg__2bLMc {
      display: block;
      height: 4.5rem;
      padding-left: 1.2rem;
      padding-top: .6rem;
      padding-right: 1.5rem;
      margin-top: .5rem; } }

.TopNav_PoolPartyText__12n7Q {
  font-size: 3.2rem;
  padding-left: 1rem; }
  @media (min-width: 700px) {
    .TopNav_PoolPartyText__12n7Q {
      font-size: 6rem;
      padding-left: 0; } }

.TopNav_HeaderEmoji__2L5RG {
  display: none; }
  @media (min-width: 700px) {
    .TopNav_HeaderEmoji__2L5RG {
      display: block;
      font-size: 5rem;
      padding-left: 2.5rem;
      padding-right: 2.5rem; } }
  .TopNav_HeaderEmoji__2L5RG:focus {
    outline: 0;
    text-decoration: underline; }

.TopNav_HeaderEmojiButton__28rjL {
  display: block;
  font-size: 2.8rem;
  padding-left: .8rem;
  padding-right: .8rem; }
  @media (min-width: 700px) {
    .TopNav_HeaderEmojiButton__28rjL {
      display: block;
      font-size: 5rem;
      padding-left: 2.5rem;
      padding-right: 2.5rem; } }
  .TopNav_HeaderEmojiButton__28rjL:focus {
    outline: 0;
    text-decoration: underline; }

.TopNav_AuthProcessingContainer__2PoRW {
  position: relative; }

.TopNav_Auth__2Bv1A {
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-right: 1.5rem;
  max-width: 28rem;
  border: 0.5rem solid #8b33e2;
  color: #000000;
  border-radius: 5rem;
  background-color: #ffffff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }
  @media (min-width: 700px) {
    .TopNav_Auth__2Bv1A {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      padding-left: 2.5rem;
      padding-right: 2.5rem; } }
  .TopNav_Auth__2Bv1A:hover {
    color: #ffffff;
    background-color: #8b33e2;
    cursor: pointer; }
    .TopNav_Auth__2Bv1A:hover > .TopNav_ProcessingIcon__3r0dp {
      display: none; }
    .TopNav_Auth__2Bv1A:hover > .TopNav_ProcessingIconHover__1X147 {
      display: inline; }
  .TopNav_Auth__2Bv1A:focus {
    outline: 0;
    text-decoration: underline; }

.TopNav_ProcessingIcon__3r0dp,
.TopNav_ProcessingIconHover__1X147 {
  height: 3.25rem;
  width: 3.25rem;
  stroke: black;
  margin-left: -1.5rem;
  margin-right: 1rem;
  margin-top: -1rem;
  margin-bottom: -1rem; }

.TopNav_ProcessingIconHover__1X147 {
  display: none; }

.TopNav_ModalContainer___xFDO {
  position: relative;
  top: 2rem;
  padding: 1.2rem;
  max-width: 28rem;
  text-align: center;
  border: 0.5rem solid #8b33e2;
  box-shadow: .6rem .6rem .05rem #333333;
  background-color: #ffffff; }

.TopNav_CloseButton__1bXTs {
  position: absolute;
  right: .2rem;
  top: .2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 2.5rem;
  background: 0;
  color: #000000; }

.TopNav_ModalTitle__3CNVU {
  color: #000000;
  font-size: 3.2rem;
  margin-top: .5rem;
  padding-right: 2.6rem; }

.TopNav_ModalText__3bD0g {
  margin-top: 1rem;
  color: #000000;
  font-size: 2rem; }

.TopNav_ModalButton__1eJYQ {
  transition: all .15s linear;
  border: 0.5rem solid #8b33e2;
  border-radius: 5rem;
  min-height: 6rem;
  width: 100%;
  margin-top: 2rem;
  padding-top: .4rem;
  padding-bottom: .4rem;
  color: white;
  background-color: #8b33e2;
  font-size: 2.8rem;
  font-weight: 600; }
  .TopNav_ModalButton__1eJYQ:hover {
    background-color: #ffffff;
    color: #000000; }

.TopNav_Token__3vJlc {
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-left: 1.5rem;
  max-width: 28rem;
  border: 0.5rem solid #8b33e2;
  color: #ffffff;
  border-radius: 5rem;
  font-weight: bolder;
  background-color: #8b33e2;
  transition: all .15s linear;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: wrap; }
  @media (min-width: 700px) {
    .TopNav_Token__3vJlc {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      margin-left: 1rem; } }
  .TopNav_Token__3vJlc:hover {
    color: #ffffff;
    background-color: #8b33e2;
    cursor: pointer; }
  .TopNav_Token__3vJlc:focus {
    outline: 0;
    text-decoration: underline; }

@keyframes NavModal_fadeIn__2S9M3 {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes NavModal_fadeOut__2o7Ac {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.NavModal_FadeIn__36t3U {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: NavModal_fadeIn__2S9M3;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.NavModal_FadeOut__3kbPj {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: NavModal_fadeOut__2o7Ac;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.NavModal_Flash__1_DpX {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: NavModal_fadeIn__2S9M3, NavModal_fadeOut__2o7Ac;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .NavModal_Flash__1_DpX p {
    font-weight: 900; }

@keyframes NavModal_fadeIn__2S9M3 {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes NavModal_fadeOut__2o7Ac {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.NavModal_FadeIn__36t3U {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: NavModal_fadeIn__2S9M3;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.NavModal_FadeOut__3kbPj {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: NavModal_fadeOut__2o7Ac;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.NavModal_Flash__1_DpX {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: NavModal_fadeIn__2S9M3, NavModal_fadeOut__2o7Ac;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .NavModal_Flash__1_DpX p {
    font-weight: 900; }

.NavModal_ModalWrapper__2cgFm {
  right: 1rem;
  width: 18rem;
  margin-top: -1.5rem;
  padding-top: 3rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  position: absolute;
  z-index: 80;
  background-color: #ffffff;
  color: #000000;
  border-radius: 2rem;
  border: 0.5rem solid #8b33e2; }
  @media (min-width: 700px) {
    .NavModal_ModalWrapper__2cgFm {
      right: 3.6rem;
      width: 24rem;
      margin-top: 1rem;
      padding-top: 4rem;
      padding-bottom: 2.5rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem; } }

.NavModal_CloseButton__1_faH {
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem; }

.NavModal_NavLink__2ZACq {
  display: block;
  width: 100%; }
  .NavModal_NavLink__2ZACq:hover {
    color: #711cc6; }

.NavModal_SelectedLink__3Y_yE {
  color: #8b33e2; }

.Backdrop_Backdrop__2dt_i {
  position: fixed;
  z-index: 20;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

@keyframes Header_fadeIn__2fT-J {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes Header_fadeOut__3SfLd {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.Header_FadeIn__1WRbi {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Header_fadeIn__2fT-J;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Header_FadeOut__2IYlx {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Header_fadeOut__3SfLd;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Header_Flash__2IfTa {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Header_fadeIn__2fT-J, Header_fadeOut__3SfLd;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .Header_Flash__2IfTa p {
    font-weight: 900; }

.Header_Header__214zg {
  position: relative;
  z-index: 50;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #1a1a1a; }

.Header_Disclaimer__3VEni {
  width: 100%;
  text-align: center;
  background-color: #8b33e2;
  padding: 1rem; }

@keyframes fadeIn {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes fadeOut {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.FadeIn {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: fadeIn;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.FadeOut {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: fadeOut;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Flash {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: fadeIn, fadeOut;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .Flash p {
    font-weight: 900; }

.Footer {
  position: absolute;
  bottom: 0;
  background-color: #F8F8F8;
  height: 15rem;
  display: flex;
  flex-direction: row;
  align-items: margin-top;
  justify-content: center;
  font-size: 1em;
  color: black;
  border-top: 2px solid #010101;
  width: 100%;
  padding-top: 3rem; }

.Column {
  width: 25rem;
  align-items: right;
  justify-content: flex-start;
  display: block;
  padding-right: 5rem;
  padding-left: 5rem;
  padding-top: 2rem; }

.ColumnTitle {
  color: #8b33e2;
  font-family: "Permanent Marker", cursive; }

.FooterRow {
  padding: 0.25rem; }

@keyframes fadeIn {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes fadeOut {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.FadeIn {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: fadeIn;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.FadeOut {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: fadeOut;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Flash {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: fadeIn, fadeOut;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .Flash p {
    font-weight: 900; }

@keyframes fadeIn {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes fadeOut {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.FadeIn {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: fadeIn;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.FadeOut {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: fadeOut;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Flash {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: fadeIn, fadeOut;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .Flash p {
    font-weight: 900; }

@keyframes fadeIn {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes fadeOut {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.FadeIn {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: fadeIn;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.FadeOut {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: fadeOut;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Flash {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: fadeIn, fadeOut;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .Flash p {
    font-weight: 900; }

.react-datepicker {
  font-size: 1em; }

.react-datepicker__header {
  padding-top: 0.8em; }

.react-datepicker__month {
  margin: 0.4em 1em; }

.react-datepicker__day-name, .react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em; }

.react-datepicker__day--selected {
  background-color: #8b33e2; }

.react-datepicker__current-month {
  font-size: 1em; }

.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent; }

.react-datepicker__navigation--previous {
  border-top-color: #8b33e2;
  border-left-color: #8b33e2;
  border-bottom-color: #8b33e2;
  border-right-color: grey;
  left: .2em;
  box-shadow: none; }

.react-datepicker__navigation--next {
  border-top-color: #8b33e2;
  border-right-color: #8b33e2;
  border-bottom-color: #8b33e2;
  border-left-color: grey;
  box-shadow: none;
  right: .2em; }

.Card {
  box-sizing: border-box;
  overflow: hidden;
  background-color: #1a1a1a;
  border-radius: 4rem;
  border: 1rem solid #8b33e2;
  padding-top: 3.6rem;
  padding-bottom: 2.6rem;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: left; }

.ButtonRaised {
  font-family: "Muli", sans-serif;
  border-radius: 15px;
  box-shadow: 2px, 2px; }

.InputRounded {
  min-width: 8.9rem;
  padding: 0.5rem;
  min-height: 1rem;
  box-sizing: border-box;
  border: 1px solid #d6d6d6;
  outline: none;
  border: none;
  background-color: white;
  border: solid #d6d6d6 1px;
  min-width: 300px;
  max-width: 100%;
  font-size: 21px;
  padding: 20px 20px 10px 20px;
  border-radius: 35px; }

* {
  box-sizing: border-box; }

html {
  font-size: 10px;
  scroll-behavior: smooth; }

body {
  background-color: #1a1a1a;
  color: #ffffff; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "VT323", monospace;
  color: #ffffff; }

p,
div,
a,
button {
  font-family: "Ubuntu", sans-serif;
  margin: 0px;
  font-size: 1.4rem; }
  @media (min-width: 700px) {
    p,
    div,
    a,
    button {
      font-size: 1.8rem; } }

p,
div {
  color: #ffffff; }

a,
button {
  color: #000000; }
  a:active,
  button:active {
    outline: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 400; }

h1 {
  font-size: 7rem; }

h2 {
  font-size: 3.8rem; }

h3 {
  font-size: 3rem; }

h4 {
  font-size: 2.4rem; }

h5 {
  font-size: 2rem; }

h6 {
  font-size: 1.8rem; }

.Data {
  font-family: "Share Tech Mono", sans-serif;
  font-weight: 400;
  word-break: break-all;
  margin: 0; }

.Bold {
  font-weight: 900; }

a {
  text-decoration: none;
  transition: all 0.15s linear; }

button {
  border: 0;
  cursor: pointer; }

.App {
  min-height: 100vh;
  width: 100vw;
  position: relative;
  padding-bottom: 15rem; }

.LinkButton {
  background-color: transparent;
  color: #8b33e2;
  padding: 0px;
  margin: 0px; }
  .LinkButton:hover {
    background-color: transparent;
    color: #711cc6; }

button.Tab {
  background-color: transparent;
  font-size: 1em; }

.ButtonGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; }

.ProposalCard,
.MemberCard {
  padding: 25px;
  border-top: 2px solid #eee; }

.ProposalDetail {
  padding: 25px;
  padding-bottom: 120px; }

.MemberDetail {
  padding: 25px;
  padding-bottom: 120px; }

.Offer {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start; }
  .Offer div:first-child {
    margin-right: 25px; }
  .Offer h5 {
    margin: 0px; }
  .Offer h2 {
    margin: 0px; }

.MainNav {
  display: flex;
  align-content: center;
  justify-content: space-evenly; }

.Timer {
  display: flex;
  align-content: center; }
  .Timer svg {
    margin: 0;
    margin-top: -4px;
    margin-right: 5px;
    fill: #ffffff; }
  .Timer p {
    margin: 0; }

.IconWarning svg {
  fill: #ef495f; }

.QR svg:not(.IconRight) {
  padding: 25px;
  background-color: #ffffff; }

.AddDevice {
  max-width: 600px;
  margin: 50px auto;
  background-color: #343434;
  text-align: center;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); }
  .AddDevice button {
    margin: 0 auto; }

.View {
  padding-bottom: 117px; }

.Pad {
  padding-left: 15px;
  padding-right: 15px; }

.Row {
  display: flex;
  align-content: center;
  justify-content: space-between; }

.Label {
  margin: 0;
  margin-bottom: 5px;
  color: #b6b6b6; }

.Danger {
  color: #ef495f; }

.AltOption {
  display: block;
  text-align: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 25px 25px;
  margin: 0 auto; }

.Video {
  max-width: 100%; }
  .Video div:first-child {
    max-width: 100%; }

.FlexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .FlexCenter .Form {
    max-width: 600px;
    width: 100%; }

.FlexContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1.5rem;
  width: calc(100% - 30px);
  overflow-x: hidden; }

.Truncate {
  width: 65px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 5px; }

.Strikethrough {
  text-decoration: line-through; }

.EthLogo {
  width: 36px; }

.Note {
  display: inline-block;
  margin-left: 5px;
  padding: 0px 5px;
  cursor: pointer; }
  .Note.Allowance:hover:after {
    content: "Your allowance is lower than your balance. Consider giving it a raise.";
    font-family: "Ubuntu", sans-serif;
    font-size: 0.5em;
    vertical-align: middle;
    margin-left: 5px; }
  .Note.Gas:hover:after {
    content: "Your Eth is really low. Consider topping it off.";
    font-family: "Ubuntu", sans-serif;
    font-size: 0.5em;
    vertical-align: middle;
    margin-left: 5px; }

.Form {
  padding: 1.5rem;
  max-width: 340px;
  margin: 0 auto; }
  .Form .Error {
    color: red;
    font-size: 13px;
    position: relative;
    top: 20px;
    left: 20px;
    margin-bottom: 40px; }

.Field {
  position: relative;
  margin-top: 25px; }
  .Field label {
    pointer-events: none;
    position: absolute;
    left: 20px;
    top: 17px;
    color: #a460e8;
    background-color: transparent;
    z-index: 10;
    transition: transform 150ms ease-out, font-size 150ms ease-out; }
  .Field.HasValue label {
    transform: translateY(-75%);
    font-size: 0.75em; }

.EmailConfirmed {
  margin: 25px auto 0px;
  max-width: 340px;
  color: #4dcc2c;
  text-transform: capitalize; }
  .EmailConfirmed svg {
    fill: #4dcc2c;
    vertical-align: middle; }

.AccountAddr {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%; }
  .AccountAddr .Label {
    display: block; }

.RiskyBiz {
  margin: 25px auto;
  font-weight: 500;
  background-color: #ef495f; }
  .RiskyBiz:hover {
    background-color: #eb1a36; }
  .RiskyBiz.Short {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .RiskyBiz.Short svg,
    .RiskyBiz.Short span {
      margin: 5px auto; }
  @media (max-width: 700px) {
    .RiskyBiz {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 0px; }
      .RiskyBiz svg,
      .RiskyBiz span {
        margin: 5px auto; } }

@keyframes Processing_fadeIn__NyCeP {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes Processing_fadeOut__3Vwwi {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.Processing_FadeIn__3njjW {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Processing_fadeIn__NyCeP;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Processing_FadeOut__3siDO {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Processing_fadeOut__3Vwwi;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Processing_Flash__3xSS3 {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Processing_fadeIn__NyCeP, Processing_fadeOut__3Vwwi;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .Processing_Flash__3xSS3 p {
    font-weight: 900; }

@keyframes Processing_fadeIn__NyCeP {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@keyframes Processing_fadeOut__3Vwwi {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.Processing_FadeIn__3njjW {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Processing_fadeIn__NyCeP;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Processing_FadeOut__3siDO {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Processing_fadeOut__3Vwwi;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1; }

.Processing_Flash__3xSS3 {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  animation: Processing_fadeIn__NyCeP, Processing_fadeOut__3Vwwi;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: #8b33e2;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .Processing_Flash__3xSS3 p {
    font-weight: 900; }

.Processing_LoaderBackground__3uML6 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  background: rgba(255, 255, 255, 0.9); }

.Processing_Loader__NHoCV {
  margin-top: 12rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 2rem;
  border: solid 0.2rem #B298DC;
  box-shadow: 0.6rem 0.6rem 0.05rem #B298DC;
  border-radius: 1.5rem;
  background-color: #ffffff; }
  @media (min-width: 700px) {
    .Processing_Loader__NHoCV {
      margin: 1rem; } }

.Processing_LoaderText__37ZwU {
  margin-top: 2rem;
  color: #000000; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

