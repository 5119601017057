@import 'styles/_variables.scss';
@import "styles/theme.scss";


.Faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 8rem;
}

.ContainerMain {
  @extend .Card;
  min-height: 50vh;
  width: 80vw;
  max-width: 80rem;
  min-width: 30rem;
  display: flex;
  flex-direction: column;
  color: $text-medium;
  padding: 2.4rem;
  background-color: white;
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.CardTitle {
  margin: 3rem 0 0.5rem;
  font-size: 4.2rem;
  font-weight: 700;
  word-wrap: break-word;
  color: $primary;
  text-align: center;
}

.SubHeader {
  margin: 3rem 0.5rem 0.5rem 0.5rem;
  font-size: 2rem;
  font-weight: bold;
  color: black;
}

.Text {
  margin: 1rem 0.5rem 1rem 0.5rem;
  font-size: 1.75rem;
  font-weight: normal;
  text-align: left;
  color: black;
}
