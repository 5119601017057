@import 'styles/_variables.scss';
@import 'styles/_tools.scss';

.ModalWrapper {
  @include breakpoint(small) {
    right: 3.6rem;
    width: 24rem;
    margin-top: 1rem;
    padding-top: 4rem;
    padding-bottom: 2.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  right: 1rem;
  width: 18rem;
  margin-top: -1.5rem;
  padding-top: 3rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;

  position: absolute;
  z-index: 80;
  background-color: $text-white;
  color: $dark-font-color;
  border-radius: 2rem;
  border: .5rem solid $primary;
}

.CloseButton {
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.NavLink {
  display: block;
  width: 100%;
  &:hover {
    color: $primary-hover;
  }
}

.SelectedLink {
  color: $primary;
}
