@import 'styles/_variables.scss';

.IconButton {
  padding: 0;
  background: 0;
  border-radius: 5rem;
  &:focus {
    outline: 0;
    text-decoration: underline;
  }
  > .Emoji {
    outline: 0;
  }
}
