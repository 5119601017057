@import 'styles/_variables.scss';
@import 'styles/_tools.scss';


.LoaderBackground {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  background: rgba(255, 255, 255, 0.9);
}

.Loader {
  @include breakpoint(small) {
    margin: 1rem;
  }
  margin-top: 12rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 2rem;
  border: solid .2rem $secondary;
  box-shadow: .6rem .6rem .05rem $secondary;
  border-radius: 1.5rem;
  background-color: $text-white;
}

.LoaderText {
  margin-top: 2rem;
  color: $text-dark;
}
