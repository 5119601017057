@import 'styles/_variables.scss';

.Loading {
    width: 100%;
    height: 100%;
    position: fixed;
    top:10rem;
    left:0;
    background: $app-background;
    display: block;
    z-index: 10;
    svg {
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);
        width: 8rem;
        height: 8rem;
    }
}
