@import "styles/_variables.scss";
@import 'styles/custom-datepicker.scss';

:export {
  primaryColor: $primary;
  secondaryColor: $secondary;
  textDark: $text-dark;
  titleFont: $title-font;
  baseFont: $base-font;
}

.Card {
  box-sizing: border-box;
  overflow: hidden;
  background-color: $app-background;
  border-radius: 4rem;
  border: 1rem solid $primary;
  padding-top: 3.6rem;
  padding-bottom: 2.6rem;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: left;
}

.ButtonRaised {
  font-family: $alt-font;
  border-radius: 15px;
  box-shadow: 2px, 2px;
}

.InputRounded {
  min-width: 8.9rem;
  padding: 0.5rem;
  min-height: 1rem;
  box-sizing: border-box;
  border: 1px solid #d6d6d6;
  outline: none;
  border: none;
  background-color: white;
  border: solid #d6d6d6 1px;
  min-width: 300px;
  max-width: 100%;
  font-size: 21px;
  padding: 20px 20px 10px 20px;
  border-radius: 35px;
}
