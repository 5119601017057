@import 'styles/_variables.scss';

.Footer {
  position: absolute;
  bottom: 0;
  background-color: #F8F8F8;
  height: 15rem;
  display: flex;
  flex-direction: row;
  align-items: margin-top;
  justify-content: center;
  font-size: 1em;
  color: black;
  border-top: 2px solid $app-dark;
  width: 100%;
  padding-top: 3rem;
}

.Column {
  width: 25rem;
  align-items: right;
  justify-content: flex-start;
  display: block;
  padding-right: 5rem;
  padding-left: 5rem;
  padding-top: 2rem;
}

.ColumnTitle {
  color: $primary;
  font-family: $marker-font;
}

.FooterRow {
  padding: 0.25rem;
}
