@import "styles/_variables.scss";
@import "styles/theme.scss";


$title: $title-font;
.BaseContainer {
  @extend .Card;
  background-color: white;
}

.BaseBody {
  color: $primary;
  font-family: $title;

}

.ErrorContainer {
  @extend .Card;
  background-color: white;
  font-family: $title;
  color: red;

}

.ErrorBody {
  @extend .BaseBody;
  color: $text-dark;
}

.PeepsContainer {
  @extend .BaseContainer;

}


.SuccessContainer {
  @extend .BaseContainer;
  background-color: rgba($color: #2aaf3a, $alpha: 1.0);
}

.SuccessBody {
  @extend .BaseBody;
  color: $text-dark;
}

.ProgressBar {
  background: $text-medium;
}
