@import 'styles/_variables.scss';
@import "styles/theme.scss";

.MainCard {
  @extend .Card;
  transition: all .15s linear;
  max-width: 40rem;
  min-height: 40rem;
  margin-bottom: 7rem;
  padding-right: .5rem;
  display: flex;
  flex-flow: column;

  &:hover {
    background-color: $text-white;
    > .TitleText,
    .DescriptionText,
    .CardStats,
    .StatText {
      color: $text-dark;
    }
  }
}

.TitleText {
  margin-bottom: 2.4rem;
}

.DescriptionText {
  margin-bottom: 2.6rem;
  font-size: 2.4rem;
}

.CardStats {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.StatText {
  width: 50%;
  margin-bottom: 1.8rem;
  font-size: 2.4rem;
  line-height: 2.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.NewTag {
  float: right;
  background: red;
  border-radius: 10rem;
  min-width: 20%;
  min-height: 2.5rem;
  text-align: center;
  align-content: left;
  font-weight: bolder;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-top: -2rem;
  z-index: 1;


}
