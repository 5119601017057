@import '~react-datepicker/dist/react-datepicker-cssmodules.css';

.react-datepicker {
  font-size: 1em;
}
.react-datepicker__header {
  padding-top: 0.8em;
}
.react-datepicker__month {
  margin: 0.4em 1em;
}
.react-datepicker__day-name, .react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
  // background-color: $primary;
}

.react-datepicker__day--selected {
  background-color: $primary;
}

.react-datepicker__current-month {
  font-size: 1em;
}
.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
  border-top-color: $primary;
  border-left-color: $primary;
  border-bottom-color: $primary;
  border-right-color: grey;
   // border: black .1rem;
  left: .2em;
  box-shadow: none;
}
.react-datepicker__navigation--next {
  border-top-color: $primary;
  border-right-color: $primary;
  border-bottom-color: $primary;
  border-left-color: grey;
  box-shadow: none;
  // border: black .1rem;
  right: .2em;
}
