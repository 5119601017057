@import "styles/_variables.scss";
@import 'styles/_tools.scss';
@import 'styles/theme.scss';

.Movement {
  @include breakpoint(small) {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 8rem;
    padding-right: 8rem;
  }
  position: relative;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Title {
  width: 100%;
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 1rem;
  font-size: 6rem;
  color: $text-white;
}

.DescriptionTextWrapper {
  max-width: 60rem;
  margin-bottom: 3rem;
  font-size: 2.4rem;
  display: flex;
  &.Expanded {
    > .DescriptionText {
      overflow: auto;
      white-space: normal;
    }
    > .MoreButton {
      display: none;
    }
  }
}

.DescriptionText {
  text-align: justify;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.MoreButton {
  &:hover {
    cursor: pointer;
  }
}

.DAOCard {
  @extend .Card;
  margin-bottom: 8rem;
  max-width: 60rem;
  width: 100%;
  background-color: $text-white;
  padding-top: 3rem;
  text-align: center;
}

.Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Amount {
  @include breakpoint(small) {
    width: 30rem;
  }
  border: .2rem solid $text-medium;
  border-radius: 3.2rem;
  height: 7.5rem;
  width: 20rem;
  padding-left: .5rem;
  padding-right: .5rem;
  text-align: center;
  font-size: 6rem;
  color: $text-dark;

  /* Firefox */
  -moz-appearance: textfield;

  &:focus {
    outline:0;
  }
}

.Error {
  color: $danger;
  margin-top: 1.625rem;
  margin-bottom: 1.5rem;
}

.MinAmountText {
  font-size: 1.8rem;
  margin-top: .75rem;
  margin-bottom: 1.5rem;
  color: $text-medium;
  text-align: center;
}

.CurrencyTypeText {
  border: .1rem solid #d6d6d6;
  border-radius: 1.6rem;
  margin-bottom: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 1.6rem;
  color: $text-dark;
  font-weight: 700;
}

.MinMaxButton {
  transition: all .15s linear;
  border: .2rem solid $primary;
  border-radius: 2rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  padding-top: .8rem;
  padding-bottom: .8rem;
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  background-color: inherit;
  font-size: 1.4rem;
  font-weight: 600;
  &:hover {
    background-color: $primary;
    color: $text-white;
  }
  &:focus {
    outline: none;
    text-decoration: underline;
  }
  &.SelectedButton {
    background-color: $primary;
    color: $text-white;
  }
}

.MainButton {
  @include breakpoint(small) {
    width: 30rem;
  }
  transition: all .15s linear;
  border: 0.5rem solid $primary;
  border-radius: 3.6rem;
  min-height: 8rem;
  width: 20rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding-top: .4rem;
  padding-bottom: .4rem;
  color: white;
  background-color: $primary;
  font-size: 3.2rem;
  font-weight: 600;

  &:hover {
    background-color: $text-white;
    color: $text-dark;
  }
}

.ButtonWarning {
  margin-top: 2 rem;
  color: red;
  font-size: 0.5 rem;
}

.PoolSnapshot {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border: .2rem solid $text-dark;
  border-radius: 3rem;
  font-family: $title-font;
}

.SnapshotHeader {
  width: 100%;
  margin-bottom: 1rem;
  text-align: center;
  color: $text-dark;
  font-size: 3.6rem;
}

.SnapshotStat {
  display: flex;
  flex-direction: column;
  min-width: 27.33%;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  color: $text-dark;
  font-family: $title-font;
  font-size: 3.5rem;
  text-align: center;
}

.SnapshotLabel {
  margin-top: .5rem;
  font-size: 2.4rem;
}

.MarginTop {
  margin-top: 2rem;
}

.MarginTopBottom {
  margin-top: 3.5rem;
  margin-bottom: 1rem;
}

.MembersList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: left;
  font-weight: normal;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.MemberListItem {
  width: 100%;
  margin-top: 2rem;
  font-weight: normal;
}

.MemberName {
  width: 100%;
  color: $text-dark;
}

.MemberInfoRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.MemberAddress,
.MemberAmount,
.MemberApproved,
.MemberIdleTokenBalance {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: $text-dark;
}

.MemberAddress {
  width: 38%;
  padding-right: 2rem;
}

.MemberAmount {
  width: 25%;
  padding-right: 2rem;
}

.MemberApproved {
  width: 45%;
}

.MemberIdleTokenBalance {
  display: inline-block;
  width: calc(100% - 7rem);
}

.MemberQuitButton {
  height: 100%;
  vertical-align: top;
  color: $primary;
  background: none;
  font-size: 1rem;
  padding-left: 1rem;
  text-decoration: underline;
}

.FilterProposalsWrapper {
  @include breakpoint(small) {
    width: calc(100% - 5rem);
  }
  width: 100%;
  margin-top: -2rem;
  margin-bottom: -1rem;
  margin-left: auto;
  margin-right: auto;
}

.FilterProposals {
  @include breakpoint(small) {
    align-items: baseline;
  }
  display: flex;
  align-items: center;
  font-size: 14px;

  > label {
    color: $text-dark;
    width: 28rem;
  }

  > select {
    min-width: 0;
  }
}

.ProposalCardWrapper {
  @include breakpoint(small) {
    width: calc(100% - 5rem);
  }
  width: 100%;
  transition: all .15s linear;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 20rem;
  margin-top: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 3rem;
  padding-bottom: 2.4rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  border-radius: 3.5rem;
  background-color: $text-medium;

  &:hover {
    background-color: $primary;
  }
}

.ProposalTitleRow {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  text-align: left;
}

.MemberTitleRow {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  text-align: left;
}

.ProposalDescription {
  margin-top: 1rem;
  text-align: left;
}

.ProposalViewButton {
  position: absolute;
  bottom: 2.4rem;
  text-decoration: underline;
}

.ProposalVotes {
  position: absolute;
  bottom: 2.4rem;
  right: 2.4rem;
}

.DetailsCard {
  position: relative;
  background-color: $text-medium;
  text-align: left;
}

.CloseButton {
  position: absolute;
  right: 2rem;
  top: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 3rem;
  color: $text-white;
}

.HeaderRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 5rem;
  margin-right: 3rem;
}

.HeaderRowTwo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 2rem;
  margin-right: 3rem;
}

.ProposalMoreInfo {
  color: $text-white;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
}

.ProposalInfoText {
  margin-top: 2rem;
}

.VoteContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
  margin-bottom: 8rem;
}

.VoteButtonWrapper {
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2.4rem;
}

.VoteButton {
  background-color: $app-background;
  margin-top: .4rem;
  height: 7.2rem;
  width: 7.2rem;
  border-radius: 10rem;
}

.DownVote {
  padding-top: 1rem;
}

.ModalForeground {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 5000;
  background-color: black;
  opacity: 0.65;
  display: none;

  &:hover {
    opacity: .85;
    > .ModalHelpText {
      display: block;
    }
  }
}

.ModalActive {
  display: block;
}

.ModalHelpText {
  text-align: center;
  position: absolute;
  top: 35vh;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4.5rem;
  display: none;
}

.CreateProposalWrapper {
  @include breakpoint(small) {
    padding-top: 5rem;
    padding-left: 5rem;
    padding-right: 5rem;
  }
  margin-bottom: 8rem;
  padding-top: 6rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 70rem;
  max-width: 100%;
  background-color: white;
  text-align: left;
  position: relative;
}

.CreateHeader {
  margin-bottom: 2rem;
  font-size: 3.2rem;
  font-weight: 500;
  font-family: $base-font;
  color: black;
  text-align: center;
}

.CreateCloseButton {
  @extend .CloseButton;
  color: black;
}

.ActionComingSoonText {
  font-family: $title-font;
  color: $text-dark;
  font-size: 4rem;
}

.DarkLabel {
  > label {
    color: black;
  }
}

.ErrorMessage {
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
  color: $danger;
  margin-top: 1rem;
  margin-bottom: -1rem;
}

.MainProposalButton {
  display: block;
  transition: all .15s linear;
  border: 0.5rem solid $primary;
  border-radius: 3.6rem;
  min-height: 8rem;
  width: 25rem;
  margin-top: 4rem;
  margin-bottom: 6rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: .4rem;
  padding-bottom: .4rem;
  color: $text-white;
  background-color: $primary;
  font-size: 3.2rem;
  font-weight: 600;

  &:hover {
    color: $text-dark;
    background-color: $text-white;
  }
}

.ProposalTypeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 6rem;
}

.ProposalTypeButton {
  @include breakpoint(small) {
    width: 38rem;
  }
  width: 25rem;
  transition: all .15s linear;
  border: 0.5rem solid $primary;
  border-radius: 1.2rem;
  min-height: 8rem;
  margin-top: 3.5rem;
  padding-top: .4rem;
  padding-bottom: .4rem;
  background-color: inherit;
  color: $text-dark;
  font-size: 3.2rem;
  font-weight: 600;

  &:hover {
    color: $text-white;
    background-color: $primary-hover;
    border: 0;
  }
  &.SelectedProposalType {
    color: $text-white;
    background-color: $primary;
    border-radius: 0;
    border: 0;
  }
}

.ProposalTypeSelector {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  border: .5rem $primary solid;
  border-radius: 1.5rem;
  max-width: 40rem;
}

.SelectorButton {
  transition: all .15s linear;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: .8rem;
  width: 50%;
  color: black;
  text-align: center;
  font-size: 2.4rem;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
}

.ActiveSelectorButton {
  background-color: $primary;
  color: white;
}

.HiddenFields {
  display: none;
}

.TextButton {
  font-size: 3.6rem;
  text-decoration: underline;
  font-family: $title-font;
  color: $primary;
  background: none;

  &:hover {
    cursor: pointer;
  }
}

.ManageSubmenu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: -1.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.MenuBreak {
  width: 100%;
  height: .1rem;

}

.ManageSubmenuButton {
  background-color: inherit;
  color: $primary;
  font-weight: 600;

  &.SelectedButton {
    text-decoration: underline;
  }

  &:first-of-type {
    margin-left: 3rem;
  }
  &:last-of-type {
    margin-right: 3rem;
  }
}

.BankTypeSelector {
  display: flex;
  border: .5rem $primary solid;
  border-radius: 1.5rem;
  max-width: 20rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.5rem;

  > .SelectorButton {
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-size: 2rem;
  }
}

.TokenTitleRow {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.TokenNameTitle,
.TokenAmtTitle,
.TokenValueTitle,
.TokenTotalValueTitle {
  white-space: nowrap;
  overflow: hidden;
  color: $text-dark;
  text-align: left;
  margin-right: 3rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.TokenNameTitle,
.TokenAddress {
  width: 30.5%;
}

.TokenAmtTitle,
.TokenAmt {
  width: 20%;
}

.TokenValueTitle,
.TokenValue {
  width: 20%;
}

.TokenTotalValueTitle,
.TokenTotalValue {
  width: 25%;
}

.MemberNameTitle,
.MemberSharesTitle,
.MemberLootTitle,
.MemberDepositsValueTitle {
  color: $text-dark;
  text-align: left;
  margin-right: 3rem;
  margin-bottom: -2rem;
  font-weight: 600;
}

.MemberNameTitle {
  width: 27.5%;
  margin-left: 1rem;
}

.MemberSharesTitle,
.MemberLootTitle {
  width: 20%;
}

.MemberSharesTitle {
  margin-right: 0rem;
}

.MemberLootTitle {
  margin-left: 1rem;
  margin-right: -1rem;
}

.MemberDepositsValueTitle {
  width: 25%;
  margin-right: 7rem;
  margin-left: 2rem;
}



.Withdraw {
  text-align: left;
  margin-top: -.75rem;
  margin-left: -2.6%;
  margin-bottom: 1rem;

}

.TokenRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.TokenName,
.TokenAddress,
.TokenAmt,
.TokenValue,
.TokenTotalValue {
  font-size: 1.4rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: $text-dark;
  text-align: left;
  padding-right: .75rem;
}

.TokenName {
  width: 100%;
  font-size: 1.6rem;
  font-weight: 600;
}

.WithdrawalButton {
  color: $primary;
  background: inherit;
  font-size: 1.3rem;
  margin-left: 1rem;
  text-decoration: underline;
}
