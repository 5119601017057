@import 'styles/_variables.scss';
@import 'styles/theme.scss';
@import 'styles/_tools.scss';

.TopNav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font: $title-font;
  width: 100%;
  height: 8.4rem;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
}

.LeftNav,
.RightNav {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 60%;
}

.TokenNav {
  align-items: flex-start;
  margin-left: 16rem;
  margin-right: 1rem;
}

.LeftNav {
  &:focus {
    outline: 0;
    text-decoration: underline;
  }
}

.BrandImg {
  @include breakpoint(small) {
    display: block;
    height: 4.5rem;
    padding-left: 1.2rem;
    padding-top: .6rem;
    padding-right: 1.5rem;
    margin-top: .5rem;
  }
  display: none;
}

.PoolPartyText {
  @include breakpoint(small) {
    font-size: 6rem;
    padding-left: 0;
  }
  font-size: 3.2rem;
  padding-left: 1rem;
}

.HeaderEmoji {
  @include breakpoint(small) {
    display: block;
    font-size: 5rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  display: none;

  &:focus {
    outline: 0;
    text-decoration: underline;
  }
}

.HeaderEmojiButton {
  @include breakpoint(small) {
    display: block;
    font-size: 5rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  display: block;
  font-size: 2.8rem;
  padding-left: .8rem;
  padding-right: .8rem;

  &:focus {
    outline: 0;
    text-decoration: underline;
  }
}

.AuthProcessingContainer {
  position: relative;
}

.Auth {
  @include breakpoint(small) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-right: 1.5rem;

  max-width: 28rem;
  border: .5rem solid $primary;
  color: $text-dark;
  border-radius: 5rem;
  background-color: $text-white;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:hover {
    color: $text-white;
    background-color: $primary;
    cursor: pointer;

    > .ProcessingIcon {
      display: none;
    }

    > .ProcessingIconHover {
      display: inline;
    }
  }

  &:focus {
    outline: 0;
    text-decoration: underline;
  }
}

.ProcessingIcon,
.ProcessingIconHover {
  height: 3.25rem;
  width: 3.25rem;
  stroke: black;
  margin-left: -1.5rem;
  margin-right: 1rem;
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.ProcessingIconHover {
  display: none;
}

.ModalContainer {
  @extend .Card;
  position: relative;
  top: 2rem;
  padding: 1.2rem;
  max-width: 28rem;
  text-align: center;
  border: .5rem solid $primary;
  box-shadow: .6rem .6rem .05rem #333333;
  background-color: $text-white;
}

.CloseButton {
  position: absolute;
  right: .2rem;
  top: .2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 2.5rem;
  background: 0;
  color: $text-dark;
}

.ModalTitle {
  color: $text-dark;
  font-size: 3.2rem;
  margin-top: .5rem;
  padding-right: 2.6rem;
}

.ModalText{
  margin-top: 1rem;
  color: $text-dark;
  font-size: 2rem;
}

.ModalButton {
  transition: all .15s linear;
  border: 0.5rem solid $primary;
  border-radius: 5rem;
  min-height: 6rem;
  width: 100%;
  margin-top: 2rem;
  padding-top: .4rem;
  padding-bottom: .4rem;
  color: white;
  background-color: $primary;
  font-size: 2.8rem;
  font-weight: 600;

  &:hover {
    background-color: $text-white;
    color: $text-dark;
  }
}

.Token {
  @include breakpoint(small) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    margin-left: 1rem;
  }
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-left: 1.5rem;

  max-width: 28rem;
  border: .5rem solid $primary;
  color: $text-white;
  border-radius: 5rem;
  font-weight: bolder;
  background-color: $primary;
  transition: all .15s linear;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: wrap;
  &:hover {
    color: $text-white;
    background-color: $primary;
    cursor: pointer;
  }

  &:focus {
    outline: 0;
    text-decoration: underline;
  }
}
