

.community-board-body {
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5rem;
  width: 80%;
  max-width: 120rem;
  border-radius: 1.25rem;
  background-color: white;
  color: black;
}

.PageTitle {
  width: 100%;
  font-size: 5rem;
  line-height: 6rem;
  font-weight: 600;
  color: #8B33E2;
  text-align: center;
}

.Update {
  font-size: 2rem;
  color: red;
  text-align: center;
}

.community-board-explanation-text {
  font-style: normal;
  font-weight: 500;
  line-height: 2.5rem;
  font-size: 1.7rem;
  letter-spacing: 0.05em;
  margin-left: 4rem;
  margin-right: 4rem;
  margin-top: 4rem;
  color: black;

  > p {
    color: black;
  }
}

.BioDescriptionTitle {
margin: 1rem 0 .5rem;
font-weight: 600;
color: #8B33E2;
font-size: 2.5rem;
}

.italics {
  font-style: italic;
}

.table-header {
  height: 1rem;
}

.table,
.th,
.td {
  text-align: center;
  border: .1rem solid gray;
  border-collapse: collapse
}

.td,
.th {
  padding: 0;
  text-align: center;
  border: .1rem solid gray}
