@import 'styles/_variables.scss';
@import "styles/theme.scss";

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.InputContainer {
  width: 100%;
  margin:  2.5rem 0rem;
}

.InputLabel {
  display: inline-block;
  font-size: 2rem;
  padding-left: .7rem;
  text-align: left;
  color: white;
  font-weight: 500;
  font-family: $base-font;
}

.InputRounded {
  padding: 0.5rem;
  margin-top: 1rem;
  min-height: 1rem;
  box-sizing: border-box;
  border: .1rem solid #d6d6d6;
  outline: none;
  border: none;
  background-color: white;
  border: solid #d6d6d6 .1rem;
  min-width: 30rem;
  font-size: 2.5rem;
  padding: 2rem 2rem 1rem 2rem;
  border-radius: 1.5rem;
  font-family: $title-font;
}

.InputLarge {
  padding: 1.4rem;
  color: $text-dark;
  width: 100%;
  min-width: 100%;
  min-height: 5rem;
}

.TextArea {
  min-height: 14rem;
}
