@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=VT323&display=swap');
@import url("https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap");
@import url('https://fonts.googleapis.com/css?family=Bungee+Shade&display=swap');

// Color Palette
$app-background: #1a1a1a;
$app-light: lighten($app-background, 10%);
$app-dark: darken($app-background, 10%);
$primary: #8b33e2;
$secondary: #B298DC;
$text-dark: #000000;
$text-medium: #555555;
$text-light: #777777;
$text-white: #ffffff;

$tertiary: rgba(239, 73, 123, 1);
$primary-hover: darken($primary, 10%);
$secondary-hover: darken($secondary, 10%);
$tertiary-hover: darken($tertiary, 10%);
$danger: rgba(239, 73, 95, 1);
$danger-hover: darken($danger, 10%);
$success: rgba(77, 204, 44, 1);
$bright: rgba(189, 134, 254, 1);


// fonts
$base-font: "Ubuntu", sans-serif;
$title-font: "VT323", monospace;
$title-shade: "Bungee Shade", cursive;
$marker-font: "Permanent Marker", cursive;
$alt-font: "Muli", sans-serif;
$data-font: "Share Tech Mono", sans-serif;
$text-size-medium: 1.8rem;
$text-size-mobile: 1.4rem;

$base-font-color: $text-white;
$dark-font-color: $text-dark;
$dark-font-hover: $primary;

$base-padding: 1.5rem;
$brand: "peeps_logo.png";

//Media Queries
$tablet: 900px;
$phone: 700px;

// Other Styles
$shadow: 0px 0px 1rem rgba(0, 0, 0, 0.1);

// Animations

@keyframes fadeIn {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none;
  }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all;
  }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none;
  }
}

.FadeIn {
  opacity: 0; /* make things invisible upon start */
  top: 0px;
  animation: fadeIn;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1;
}

.FadeOut {
  opacity: 0; /* make things invisible upon start */
  top: 0px;
  animation: fadeOut;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
  animation-delay: 0s;
  animation-iteration-count: 1;
}

.Flash {
  // animation in/out settings
  opacity: 0; /* make things invisible upon start */
  top: 0px;
  animation: fadeIn, fadeOut;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-duration: 0.15s, 0.15s;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1, 1;
  // specific styles
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: $primary;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-weight: 900;
  }
  z-index: 99;
}
