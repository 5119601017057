@import 'styles/_variables.scss';
@import "styles/theme.scss";


.VotesMeterContainer {
  position: relative;
  z-index: 3;
  margin-top: 3.2rem;
  margin-left: -.3rem;
  margin-right: -.3rem;
  width: 25rem;
  height: 2.0rem;
  background-color: white;
  border: .1rem solid black;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.VotesMeter {
  background-color: $primary;
  height: 2.3rem;
  border-left: .1rem solid black;
}
