@import "styles/_variables.scss";
@import 'styles/_tools.scss';


.CreateWrapper {
  max-width: 110rem;
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PageHeader {
  @include breakpoint(small) {
    font-size: 6rem;
  }
  font-size: 4rem;
  margin-bottom: 2rem;
  text-align: center;
}

.PageSubHeader {
  @include breakpoint(small) {
    font-size: 4.8rem;
  }
  font-size: 3rem;
  margin-top: 2rem;
  margin-bottom: 4rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.CreateForm {
  width: 96rem;
  max-width: 100%;
  text-align: left;
  margin-bottom: 4rem;
}

.CategoryList {
  @include breakpoint(small) {
    flex-direction: row;
    align-items: flex-start;
  }
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem;
}

.CategoryButton {
  @include breakpoint(small) {
    font-size: 4.8rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  font-size: 3rem;
  font-family: $title-font;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 25rem;
  background: none;
  color: white;
  margin-left: 0;
  margin-right: 0;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.CategoryImg {
  max-width: 25rem;
  border-radius: 2rem;
}

.ButtonRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.MainButton {
  transition: all .15s linear;
  border: 0.5rem solid $primary;
  border-radius: 3.6rem;
  min-height: 8rem;
  width: 35rem;
  margin-right: 1.5rem;
  margin-top: 2rem;
  padding-top: .4rem;
  padding-bottom: .4rem;
  color: $text-white;
  background-color: $primary;
  font-size: 3.2rem;
  font-weight: 600;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    color: $text-dark;
    background-color: $text-white;
  }
}

.SecondaryButton {
  @extend .MainButton;
  color: $text-dark;
  background-color: $text-white;

  &:hover {
    color: $text-white;
    background-color: $primary;
  }
}

.InputRow {
  display: flex;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;

  > div {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.ThirdWidth {
  width: calc(40% - 1.5rem);
  &:first-of-type {
    margin-right: 1.5rem;
  }
}

.HalfWidth {
  width: calc(50% - .75rem);
  &:first-of-type {
    margin-right: 1.5rem;
  }
}

.TwoThirdWidth {
  width: calc(60% - 1rem);
  margin-left: 1rem;
}

.ErrorMessage {
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
  color: $danger;
}

.CreateInputFields {
  max-width: 75rem;
  margin: auto;
}

.HiddenFields {
  display: none;
}

.CreateTokenList {
  max-width: 75rem;
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.TokenOption {
  max-width: calc(50% - 2rem);
  width: 32rem;
  margin-left: 2rem;
  margin-right: 2rem;
  display: flex;
  margin-bottom: 4rem;
}

.CustomTokenOption {
  @extend .TokenOption;
  max-width: 100%;
  width: 64rem;

  > .TokenCheckboxLabel
  {
    width: 100%;
    padding-top: 1rem;
    > .TokenAddress {
      max-width: 100%;
      white-space: normal;
      overflow: auto;
      text-overflow: inherit;

      > div {
        margin-top: -2rem;
      }
    }
  }
}

.TokenCheckbox {
  -webkit-appearance: none;
  width: 3.5rem;
  height: 3.5rem;
  margin-top: .8rem;
  margin-right: 2.5rem;
  background: white;
  border-radius: 1.2rem;
  border: .4rem solid white;

  &:checked {
    background: $app-dark;
  }

  &:hover {
    cursor: pointer;
  }
}

.TokenCheckboxLabel {
  &:hover {
    cursor: pointer;
  }
}

.TokenName {
  font-size: 3rem;
}

.TokenAddress {
  font-size: 1.3rem;
  max-width: 25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.AddPeepsButtonsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
}

.CreateTextButton {
  font-size: 3.6rem;
  text-decoration: underline;
  font-family: $title-font;
  color: $primary;
  background: none;

  &:hover {
    cursor: pointer;
  }
}

.CsvReaderInput {
  display: none;
}
