@import "styles/_variables.scss";
@import 'styles/_tools.scss';


.NavContainer {
  display: flex;
  justify-content: space-around;
  margin-bottom: 4rem;
}

.NavItem {
  @include breakpoint(small) {
    font-size: 3.8rem;
  }
  font-size: 3rem;
  color: $text-dark;
  &:hover {
    cursor: pointer;
  }
  &.Selected {
    text-decoration: underline;
  }
}
