@import "styles/_variables.scss";
@import 'styles/_tools.scss';

.MainContent {
  @include breakpoint(small) {
    padding-top: 5rem;
    padding-bottom: 3rem;
  }
  @include breakpoint(medium) {
    max-width: 90rem;
  }
  max-width: 50rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: left;
}

.HeaderSection {
  width: 100%;
}

.PageHeader {
  @include breakpoint(small) {
    font-size: 6.8rem;
    line-height: 6.8rem;
  }
  font-size: 4rem;
  line-height: 4rem;
}

.PageSubHeader {
  @include breakpoint(small) {
    font-size: 3.4rem;
    line-height: 3.4rem;
  }
  font-size: 1.5rem;
  line-height: 1.5rem;
  max-width: 60rem;
  margin-bottom: 5rem;
}

.UserStatsContainer {
  @include breakpoint(small) {
    flex-direction: row;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border: .5rem white solid;
  border-radius: 1rem;
  margin-bottom: 4.5rem;
  padding-top: 4rem;
  padding-bottom: 2rem;
}

.UserStat {
  font-size: 4.8rem;
  line-height: 4.8rem;
  text-align: center;
  margin-bottom: 2.5rem;
}

.StatLabel {
  font-size: 3.6rem;
  line-height: 3.6rem;
}

.DAOsContainer {
  @include breakpoint(medium) {
    max-width: 100rem;
    min-width: 90rem;
  }
  max-width: 50rem;
  min-width: 0;

  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
}
