@import 'styles/_variables.scss';


.Header {
  position: relative;
  z-index: 50;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: $app-background;
}

.Disclaimer {
  width: 100%;
  text-align: center;
  background-color: $primary;
  padding: 1rem;
}
